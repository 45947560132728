import React from 'react'
import useSWR from 'swr'
import { SvgPaperclip } from 'components/svgs/svg-paperclip'
import { NextLink } from 'components/next-link/next-link'
import styled from 'styled-components'
import { transition, wordWrap } from 'theme/utils'
import { useLocationContext } from 'components/location-provider/location-provider'
import { pageCommonFetcher, QueryName } from 'utils/url'

type AttachmentsProps = {
	attachments: Attachment[]
}

type Attachment = {
	document: {
		title: string
		file: string
	}
}

const AttachmentList = styled.ul`
	padding: 0;
	margin: 0;
	text-decoration: none;
`

const AttachmentItem = styled.li`
	list-style: none;

	&:not(:last-child) {
		margin-bottom: 4px;
	}
`

const SvgPaperclipIcon = styled(SvgPaperclip)`
	position: relative;
	top: -2px;
`

const AttachmentLink = styled.a`
	display: flex;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	color: ${props => props.theme.color.primary};
	text-decoration: underline;
	transition: ${transition('color')};

	svg {
		overflow: initial;

		path {
			transition: ${transition('fill')};
		}
	}

	&:hover,
	&:focus,
	&:active {
		color: ${props => props.theme.color.secondary};

		svg {
			path {
				fill: ${props => props.theme.color.secondary};
			}
		}
	}
`

const AttachmentsTitle = styled.p`
	${wordWrap}
	padding-left: 8px;
	margin: 0;
`

const commonQuery: QueryName = 'PageCommon'

export const Attachment = ({ document }: Attachment) => {
	const { language } = useLocationContext()
	const { data } = useSWR([commonQuery, language], pageCommonFetcher)
	const { title, file } = document

	return data ? (
		<AttachmentItem>
			<NextLink href={`${data.mediaUrl}/${file}`} passHref>
				<AttachmentLink>
					<SvgPaperclipIcon suffix={`-${file}`} />
					<AttachmentsTitle>{title}</AttachmentsTitle>
				</AttachmentLink>
			</NextLink>
		</AttachmentItem>
	) : null
}

export const Attachments = ({ attachments }: AttachmentsProps) => {
	return (
		<AttachmentList>
			{attachments.map((attachment, index) => {
				return <Attachment key={index} document={attachment.document} />
			})}
		</AttachmentList>
	)
}
