import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useMediaQueryContext } from 'components/media-query-provider/media-query-provider'
import { SectionLayout } from 'components/section-layout/section-layout'
import { mediaBreakpointUpSm, mediaBreakpointUpLg } from 'theme/breakpoints'

type EventDetailLayoutProps = {
	head: PropTypes.ReactNodeLike
	header: PropTypes.ReactNodeLike
	featuredImage: PropTypes.ReactNodeLike
	authorImage: PropTypes.ReactNodeLike
	readSpeaker: PropTypes.ReactNodeLike
	body: PropTypes.ReactNodeLike[]
	shareButtons: PropTypes.ReactNodeLike
	eventInformation: PropTypes.ReactNodeLike
	verticalAdvertisement: PropTypes.ReactNodeLike
	horizontalAdvertisement: PropTypes.ReactNodeLike
}

const EventDetailFeaturedImageWrapper = styled.div`
	position: relative;
	margin-right: -15px;
	margin-left: -15px;

	${mediaBreakpointUpSm} {
		margin-right: 0;
		margin-left: 0;
	}
`

const EventDetailBody = styled.div`
	${mediaBreakpointUpLg} {
		padding-top: 10px;
	}
`

const EventDetailReadSpeakerShareButtons = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 12px 0;

	${mediaBreakpointUpLg} {
		margin: 13px 0 16px;
	}
`

const EventDetailShareButtonsBottom = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin: 32px 0 40px;

	${mediaBreakpointUpLg} {
		margin: 39px 0 0;
	}
`

const EventDetailHorizontalAd = styled(SectionLayout)`
	${mediaBreakpointUpLg} {
		padding-top: 84px;
		padding-bottom: 100px;
	}
`

export const EventDetailLayout = ({
	head,
	header,
	featuredImage,
	authorImage,
	readSpeaker,
	body,
	shareButtons,
	eventInformation,
	verticalAdvertisement,
	horizontalAdvertisement,
}: EventDetailLayoutProps) => {
	const { isMobile } = useMediaQueryContext()

	return (
		<>
			{head}
			<SectionLayout noPaddingTop noPaddingBottom>
				<div className="container">
					<div className="row">
						<div className="col-md-10 offset-md-1 col-lg-8 offset-lg-0">{header}</div>
					</div>
					<EventDetailBody className="row">
						<div className="col-md-10 offset-md-1 col-lg-8 offset-lg-0">
							<EventDetailFeaturedImageWrapper>
								{featuredImage}
								{authorImage}
							</EventDetailFeaturedImageWrapper>
							<EventDetailReadSpeakerShareButtons>
								{readSpeaker}
								{shareButtons}
							</EventDetailReadSpeakerShareButtons>
							<div id="js-read-speaker">{body}</div>
							<EventDetailShareButtonsBottom>{shareButtons}</EventDetailShareButtonsBottom>
						</div>
						<div className="col-md-10 offset-md-1 col-lg-4 offset-lg-0">
							<div className="row">
								<div className="col-12 mb-40">{eventInformation}</div>
								{verticalAdvertisement && <div className="col-12 mb-xs-32">{verticalAdvertisement}</div>}
							</div>
						</div>
					</EventDetailBody>
				</div>
			</SectionLayout>

			{/* horizontal advertisement section */}
			{!isMobile && horizontalAdvertisement && (
				<EventDetailHorizontalAd lazy lazyHeights={{ xs: 330, xl: 370 }}>
					<div className="container">
						<div className="row">
							<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0">{horizontalAdvertisement}</div>
						</div>
					</div>
				</EventDetailHorizontalAd>
			)}
		</>
	)
}
