import React from 'react'
import { Text } from 'components/text/text'
import styled from 'styled-components'
import { Language } from 'generated/sdk'
import { useLocationContext } from 'components/location-provider/location-provider'
import { SvgArticleType } from 'components/svgs/svg-article-type'
import { SvgVideoType } from 'components/svgs/svg-video-type'
import { SvgPhotoGalleryType } from 'components/svgs/svg-photo-gallery-type'
import { mediaBreakpointUpLg } from 'theme/breakpoints'

export enum NewsTypes {
	simple = 'simple',
	photoGallery = 'photoGallery',
	video = 'video',
}

const defaultProps = {
	type: NewsTypes.simple,
}

type NewsTypeProps = typeof defaultProps

const NEWS_TYPE_TEXT = {
	[NewsTypes.simple]: {
		[Language.Pt]: 'Notícia',
		[Language.En]: 'Article',
	},
	[NewsTypes.photoGallery]: {
		[Language.Pt]: 'Fotogaleria',
		[Language.En]: 'Photo gallery',
	},
	[NewsTypes.video]: {
		[Language.Pt]: 'Vídeo',
		[Language.En]: 'Video',
	},
}

const NEWS_TYPE_IMAGE = {
	[NewsTypes.simple]: <SvgArticleType suffix={'simple'} />,
	[NewsTypes.photoGallery]: <SvgPhotoGalleryType suffix={'photogallery'} />,
	[NewsTypes.video]: <SvgVideoType suffix={'video'} />,
}

const NewsTypeWrapper = styled.div`
	display: inline-flex;
`

const NewsTypeText = styled(Text)`
	margin: 0 0 0 4px;
	font-size: 16px;
	font-weight: 500;
	line-height: 19px;
	color: ${props => props.theme.text.primary};

	${mediaBreakpointUpLg} {
		font-size: 18px;
		line-height: 23px;
	}
`

export const NewsTypeComponent = ({ type }: NewsTypeProps) => {
	const { language } = useLocationContext()

	const icon = NEWS_TYPE_IMAGE[type]
	const text = NEWS_TYPE_TEXT[type][language]

	return (
		<NewsTypeWrapper>
			{icon}
			<NewsTypeText>
				<b>{text}</b>
			</NewsTypeText>
		</NewsTypeWrapper>
	)
}

NewsTypeComponent.defaultProps = defaultProps

export const NewsType = React.memo(NewsTypeComponent)
