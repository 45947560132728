import React from 'react'
import { useMediaQueryContext } from 'components/media-query-provider/media-query-provider'
import { SvgFacebookSmall } from 'components/svgs/svg-facebook-small'
import { SvgFacebookMedium } from 'components/svgs/svg-facebook-medium'
import { SvgTwitterSmall } from 'components/svgs/svg-twitter-small'
import { SvgTwitterMedium } from 'components/svgs/svg-twitter-medium'
import styled, { ThemeProvider } from 'styled-components'
import { darkTheme } from 'theme/theme'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'
import { transition } from 'theme/utils'
import { stringifyParams, removeTrailingSlash } from 'utils/url'

export enum SocialMediaShareNetworks {
	facebook = 'facebook',
	twitter = 'twitter',
}

type SocialMediaShareProps = {
	network: SocialMediaShareNetworks
	title: string
	url: string
}

const SOCIAL_MEDIA_COLORS = {
	[SocialMediaShareNetworks.facebook]: { main: '#3b5a98', hover: '#2e477b' },
	[SocialMediaShareNetworks.twitter]: { main: '#00acee', hover: '#008dc3' },
}

const SOCIAL_MEDIA_SHARE_TEXT = {
	[SocialMediaShareNetworks.facebook]: {
		[Language.Pt]: 'Partilhar',
		[Language.En]: 'Share',
	},
	[SocialMediaShareNetworks.twitter]: {
		[Language.Pt]: 'Tweetar',
		[Language.En]: 'Tweet',
	},
}

const SOCIAL_MEDIA_SHARE_URL = {
	[SocialMediaShareNetworks.facebook]: (_: string, url: string) =>
		`https://www.facebook.com/sharer/sharer.php?${stringifyParams({ u: url })}`,
	[SocialMediaShareNetworks.twitter]: (title: string, url: string) =>
		`https://twitter.com/intent/tweet?${stringifyParams({ text: title, url })}`,
}

const SOCIAL_MEDIA_ICONS = {
	[SocialMediaShareNetworks.facebook]: {
		small: <SvgFacebookSmall suffix="-social-media-share" />,
		medium: <SvgFacebookMedium suffix="-social-media-share" />,
	},
	[SocialMediaShareNetworks.twitter]: {
		small: <SvgTwitterSmall suffix="-social-media-share" />,
		medium: <SvgTwitterMedium suffix="-social-media-share" />,
	},
}

export const SocialMediaShareLink = styled.a<{ network: SocialMediaShareNetworks; isMobile: boolean }>`
	display: inline-flex;
	align-items: center;
	padding: ${props => (props.isMobile ? '6px' : '3px 8px')};
	color: ${props => props.theme.color.primary};
	background-color: ${props => SOCIAL_MEDIA_COLORS[props.network].main};
	border-radius: 2px;
	transition: ${transition('all')};

	span {
		padding-top: 3px;
		margin-left: 4px;
		font-size: 14px;
		font-weight: 700;
		line-height: 13px;
		color: ${props => props.theme.color.primary};
	}

	&:hover {
		color: ${props => props.theme.color.primary};
		text-decoration: none;
		background-color: ${props => SOCIAL_MEDIA_COLORS[props.network].hover};
	}
`

export const SocialMediaShare = ({ network, title, url }: SocialMediaShareProps) => {
	const { isMobile } = useMediaQueryContext()
	const { language, origin } = useLocationContext()
	const absoluteUrl = url.startsWith('/') ? `${origin}${url}` : url
	return (
		<ThemeProvider theme={darkTheme}>
			<SocialMediaShareLink
				href={SOCIAL_MEDIA_SHARE_URL[network](title, removeTrailingSlash(absoluteUrl))}
				target="_blank"
				rel="nofollow"
				network={network}
				isMobile={isMobile}>
				{isMobile ? SOCIAL_MEDIA_ICONS[network].medium : SOCIAL_MEDIA_ICONS[network].small}
				{!isMobile && <span>{SOCIAL_MEDIA_SHARE_TEXT[network][language]}</span>}
			</SocialMediaShareLink>
		</ThemeProvider>
	)
}
