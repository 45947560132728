import React, { useState } from 'react'
import styled from 'styled-components'
import { TabButton } from 'components/tabs/tabs'
import { TrafficConditionStatus, trafficConditionStatusColors } from 'components/traffic-desktop/traffic-desktop'
import { mediaBreakpointUpSm, mediaBreakpointUpMd } from 'theme/breakpoints'

export type FiltersItem = {
	status?: TrafficConditionStatus
	label: string
}

type TabbedFilterProps = {
	fullWidth?: boolean
	filters: FiltersItem[]
	activeFilter?: string
	handleFilter?: (filter: FiltersItem) => void
}

const TabbedFilterWrapper = styled.ul<{ fullWidth?: boolean }>`
	display: flex;
	flex-wrap: nowrap;
	justify-content: ${props => (props.fullWidth ? 'space-between' : 'flex-start')};
	padding: 0;
	margin: 0;
	overflow-x: auto;
	list-style: none;
	-webkit-overflow-scrolling: touch;

	/* Remove scrollbar on IE11 */
	-ms-overflow-style: none;

	${mediaBreakpointUpSm} {
		justify-content: flex-start;

		/* Reset horizontal scroll to the default values */
		overflow-x: visible;
		-webkit-overflow-scrolling: auto;
		-ms-overflow-style: auto;
	}

	&::-webkit-scrollbar {
		display: none;

		${mediaBreakpointUpSm} {
			/* Reset horizontal scroll to the default values */
			display: initial;
		}
	}
`

const TabbedFilterItem = styled.li<{ fullWidth?: boolean }>`
	flex: ${props => (props.fullWidth ? '0 1 auto' : '0 0 auto')};
	padding: 0;
	margin: ${props => (props.fullWidth ? '0' : '0 10px 0 0')};

	${mediaBreakpointUpSm} {
		flex: 0 1 auto;
		margin: 0 22px 0 0;
	}

	${mediaBreakpointUpMd} {
		margin: 0 32px 0 0;
	}

	&:first-child {
		padding: ${props => (props.fullWidth ? '0' : '0 0 0 15px')};

		${mediaBreakpointUpSm} {
			padding: 0;
		}
	}

	&:last-child {
		padding: ${props => (props.fullWidth ? '0' : '0 15px 0 0')};
		margin: 0;

		${mediaBreakpointUpSm} {
			padding: 0;
		}
	}
`

const TabbedFilterButton = styled(TabButton)`
	padding: 12px 0;
	font-size: 16px;

	&::before {
		display: block;
		height: 0;
		overflow: hidden;
		font-weight: 700;
		visibility: hidden;
		content: attr(title);
	}

	&::after {
		bottom: 5px;
	}
`

const StatusTabWrapper = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
`

const StatusTabIndicator = styled.span<{ status: TrafficConditionStatus }>`
	position: relative;
	top: -1px;
	width: 10px;
	height: 10px;
	margin-right: 7px;
	background-color: ${props => trafficConditionStatusColors[props.status]};
	border-radius: 50%;
`

export const TabbedFilter = ({ fullWidth = true, filters, activeFilter, handleFilter }: TabbedFilterProps) => {
	const [activeFilterIndex, setActiveFilterIndex] = useState(0)

	const handleTabClick = (index: number): void => {
		setActiveFilterIndex(index)
		handleFilter && handleFilter(filters[index])
	}

	const isActive = (filter: FiltersItem, index: number, activeFilter?: string) => {
		return activeFilter ? activeFilter === filter.status || activeFilter === filter.label : index === activeFilterIndex
	}

	const statusTab = (label: string, status: TrafficConditionStatus) => (
		<StatusTabWrapper>
			{status !== TrafficConditionStatus.ALL && <StatusTabIndicator status={status} />}
			<span>{label}</span>
		</StatusTabWrapper>
	)

	return (
		<TabbedFilterWrapper fullWidth={fullWidth}>
			{filters.map((filter, index) => {
				return (
					<TabbedFilterItem fullWidth={fullWidth} key={index}>
						<TabbedFilterButton
							index={index}
							title={filter.label}
							isActive={isActive(filter, index, activeFilter)}
							handleTabClick={handleTabClick}>
							{filter.status ? statusTab(filter.label, filter.status) : filter.label}
						</TabbedFilterButton>
					</TabbedFilterItem>
				)
			})}
		</TabbedFilterWrapper>
	)
}
