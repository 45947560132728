import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useMediaQueryContext } from 'components/media-query-provider/media-query-provider'
import { SectionLayout } from 'components/section-layout/section-layout'
import { Tabs, TabsItem } from 'components/tabs/tabs'
import { mediaBreakpointUpLg } from 'theme/breakpoints'

type StandardPageLayoutProps = {
	head: PropTypes.ReactNodeLike
	title: PropTypes.ReactNodeLike
	body: PropTypes.ReactNodeLike[]
	readSpeaker: PropTypes.ReactNodeLike
	mostRecentTitle: string
	mostRecentItems: PropTypes.ReactNodeLike[]
	mostReadTitle: string
	mostReadItems: PropTypes.ReactNodeLike[]
	verticalAdvertisement: PropTypes.ReactNodeLike
	horizontalAdvertisement: PropTypes.ReactNodeLike
}

const StandardHeader = styled.div`
	margin: 22px 0 0;

	${mediaBreakpointUpLg} {
		margin: 40px 0 0;
	}
`

const StandardReadSpeaker = styled.div`
	margin: 12px 0;

	${mediaBreakpointUpLg} {
		margin: 13px 0 16px;
	}
`

const StandardBody = styled.div`
	padding: 10px 0 0;

	${mediaBreakpointUpLg} {
		padding: 14px 0 0;
	}
`

const StandardTabsWrapper = styled.div`
	${mediaBreakpointUpLg} {
		margin: 64px 0 30px;
	}
`

const StandardHorizontalAd = styled(SectionLayout)`
	${mediaBreakpointUpLg} {
		padding: 47px 0 100px;
	}
`

const StandardAside = styled(SectionLayout)`
	padding: 8px 0 57px;

	${mediaBreakpointUpLg} {
		padding: 0 0 100px;
	}
`

export const StandardPageLayout = ({
	head,
	title,
	body,
	readSpeaker,
	mostRecentTitle,
	mostRecentItems,
	mostReadTitle,
	mostReadItems,
	verticalAdvertisement,
	horizontalAdvertisement,
}: StandardPageLayoutProps) => {
	const { isMobile } = useMediaQueryContext()

	const mostRecentAndReadTabItems: TabsItem[] = [
		{
			title: mostRecentTitle,
			content: mostRecentItems,
		},
		{
			title: mostReadTitle,
			content: mostReadItems,
		},
	]

	return (
		<>
			{head}
			<SectionLayout noPaddingTop noPaddingBottom>
				<div className="container">
					<div className="row">
						<div className="col-md-10 offset-md-1 col-lg-8 offset-lg-0">
							<StandardHeader>{title}</StandardHeader>
						</div>
					</div>
					<StandardBody className="row">
						<div className="col-md-10 offset-md-1 col-lg-8 offset-lg-0">
							<StandardReadSpeaker>{readSpeaker}</StandardReadSpeaker>
							<div id="js-read-speaker">{body}</div>
						</div>
						{!isMobile && (
							<div className="col-lg-4">
								<div className="row">
									<div className="col-12">
										<StandardTabsWrapper>
											<Tabs tabs={mostRecentAndReadTabItems} />
										</StandardTabsWrapper>
									</div>
									{verticalAdvertisement && (
										<div className="col-12">
											<div>{verticalAdvertisement}</div>
										</div>
									)}
								</div>
							</div>
						)}
					</StandardBody>
				</div>
			</SectionLayout>

			{/* horizontal advertisement section */}
			{!isMobile && horizontalAdvertisement && (
				<StandardHorizontalAd>
					<div className="container">
						<div className="row">
							<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0">{horizontalAdvertisement}</div>
						</div>
					</div>
				</StandardHorizontalAd>
			)}

			{isMobile && (
				<StandardAside>
					<div className="container">
						<div className="row">
							{verticalAdvertisement && (
								<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0 mb-xs-24">{verticalAdvertisement}</div>
							)}
							<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0 mb-xs-31">
								<StandardTabsWrapper>
									<Tabs tabs={mostRecentAndReadTabItems} />
								</StandardTabsWrapper>
							</div>
							{horizontalAdvertisement && (
								<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0">{horizontalAdvertisement}</div>
							)}
						</div>
					</div>
				</StandardAside>
			)}
		</>
	)
}
