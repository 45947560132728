import React from 'react'
import { NextSeo } from 'next-seo'
import { StandardPageDataFragment } from 'generated/sdk'
import { useMediaQueryContext } from 'components/media-query-provider/media-query-provider'
import { useSeoContext } from 'components/seo-provider/seo-provider'
import { StandardPageLayout } from 'components/layouts/standard-page-layout/standard-page-layout'
import { PageTitle } from 'components/page-title/page-title'
import { ReadSpeakerPlugin } from 'components/read-speaker-plugin/read-speaker-plugin'
import { RichTextBlock } from 'components/rich-text-block/rich-text-block'
import { BlockQuoteBlock } from 'components/block-quote-block/block-quote-block'
import { MostReadItemRow } from 'components/most-read/most-read'
import { Advertisement, OrientationTypes } from 'components/advertisement/advertisement'
import { categoryPageDataMapper, getRenditionUrl } from 'utils/data'

type StandardPageProps = {
	pageData: StandardPageDataFragment
}

const StandardPage = ({ pageData }: StandardPageProps) => {
	const { isMobile } = useMediaQueryContext()
	const seoData = useSeoContext()

	const title = <PageTitle>{pageData.title}</PageTitle>

	const body = pageData.body
		.map((block, index) => {
			switch (block.__typename) {
				case 'RichTextBlock':
					return <RichTextBlock key={index} html={block.value} />
				case 'BlockQuoteBlock':
					return <BlockQuoteBlock key={index}>{block.value}</BlockQuoteBlock>
				default:
					return null
			}
		})
		.filter(Boolean)

	const mostRecentItems = pageData.mostRecentArticles.map(({ url, title, categoryPage, timestamp }) => ({
		url,
		title,
		category: categoryPageDataMapper(categoryPage),
		timestamp,
	}))

	const mostReadItems = pageData.mostReadArticles.map(({ url, title, categoryPage, timestamp }) => ({
		url,
		title,
		category: categoryPageDataMapper(categoryPage),
		timestamp,
	}))

	const verticalAdvertisementItems = pageData.advertisementsVertical.map(item => {
		return {
			src: getRenditionUrl(isMobile ? item.imageMobile.small : item.imageDesktop.small),
			srcRetina: getRenditionUrl(isMobile ? item.imageMobile.medium : item.imageDesktop.medium),
			srcPlaceholder: isMobile ? item.imageMobile.placeholderHash : item.imageDesktop.placeholderHash,
			alt: item.caption || item.imageMobile.title || item.imageDesktop.title,
			url: item.linkUrl || item.linkPage?.url || '',
		}
	})

	const horizontalAdvertisementItems = pageData.advertisementsHorizontal.map(item => {
		return {
			src: getRenditionUrl(isMobile ? item.imageMobile.small : item.imageDesktop.large),
			srcRetina: getRenditionUrl(isMobile ? item.imageMobile.medium : item.imageDesktop.extraLarge),
			srcPlaceholder: isMobile ? item.imageMobile.placeholderHash : item.imageDesktop.placeholderHash,
			alt: item.caption || item.imageMobile.title || item.imageDesktop.title,
			url: item.linkUrl || item.linkPage?.url || '',
		}
	})

	const tags = pageData.tags.length > 0 ? pageData.tags : seoData.tags

	return (
		<StandardPageLayout
			title={title}
			body={body}
			readSpeaker={<ReadSpeakerPlugin url={pageData.url} />}
			mostRecentTitle={pageData.mostRecentTitle}
			mostRecentItems={mostRecentItems.map((item, index) => MostReadItemRow(item, index))}
			mostReadTitle={pageData.mostReadTitle}
			mostReadItems={mostReadItems.map((item, index) => MostReadItemRow(item, index))}
			verticalAdvertisement={
				verticalAdvertisementItems.length > 0 && (
					<Advertisement items={verticalAdvertisementItems} orientation={OrientationTypes.vertical} />
				)
			}
			horizontalAdvertisement={
				horizontalAdvertisementItems.length > 0 && (
					<Advertisement items={horizontalAdvertisementItems} orientation={OrientationTypes.horizontal} />
				)
			}
			head={
				<NextSeo
					title={pageData.seoTitle || pageData.title}
					description={pageData.searchDescription || seoData.description}
					openGraph={{
						title: pageData.seoTitle || pageData.title,
						description: pageData.searchDescription || seoData.description,
						url: pageData.fullUrl || '',
						type: 'website',
						images: [
							{
								url: seoData.sharableImage.rendition?.url || '',
								alt: seoData.title,
							},
						],
					}}
					twitter={{
						cardType: 'summary_large_image',
					}}
					{...(tags.length > 0 && {
						additionalMetaTags: [
							{
								name: 'keywords',
								content: tags.map(item => item.name).join(', '),
							},
						],
					})}
				/>
			}
		/>
	)
}

export default StandardPage
