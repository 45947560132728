import React from 'react'
import { NextSeo } from 'next-seo'
import { ArticleDetailDataFragment, PhotoGalleryDetailDataFragment, VideoDetailDataFragment } from 'generated/sdk'
import { categoryPageDataMapper, getRenditionUrl, getSlideThumbnail } from 'utils/data'
import { NewsDetailLayout } from 'components/layouts/news-detail-layout/news-detail-layout'
import { OpenGraphImages } from 'next-seo/lib/types'
import { ReadSpeakerPlugin } from 'components/read-speaker-plugin/read-speaker-plugin'
import { RichTextBlock } from 'components/rich-text-block/rich-text-block'
import { BlockQuoteBlock } from 'components/block-quote-block/block-quote-block'
import { Advertisement, OrientationTypes } from 'components/advertisement/advertisement'
import { MostReadItemRow } from 'components/most-read/most-read'
import { useMediaQueryContext } from 'components/media-query-provider/media-query-provider'
import { SectionTitle } from 'components/section-title/section-title'
import { PrimaryButton } from 'components/primary-button/primary-button'
import { NewsCard, NewsCardTypes } from 'components/news-card/news-card'
import { NewsDetailHeader } from 'components/news-detail-header/news-detail-header'
import { LazyImage } from 'components/lazy-image/lazy-image'
import {
	SocialMediaShare,
	SocialMediaShareNetworks,
	SocialMediaShareLink,
} from 'components/social-media-share/social-media-share'
import styled from 'styled-components'
import { boxShadowRgbaWithHex } from 'theme/utils'
import { mediaBreakpointUpMd } from 'theme/breakpoints'
import { NewsDetailPhotoGalleryHero } from 'components/news-detail-photo-gallery-hero/news-detail-photo-gallery-hero'
import { NewsDetailVideoHero } from 'components/news-detail-video-hero/news-detail-video-hero'
import { getYouTubeId } from 'utils/url'
import { useSeoContext } from 'components/seo-provider/seo-provider'
import { Attachments } from 'components/attachment/attachments'
import { CreditsSpacing, Credits } from 'components/credits/credits'

type NewsDetailPageProps = {
	pageData: ArticleDetailDataFragment | PhotoGalleryDetailDataFragment | VideoDetailDataFragment
}

export const NewsDetailFeaturedImage = styled.div`
	${props => boxShadowRgbaWithHex('0 1px 2px 0', props.theme.colors.black, 0.3)}
	${mediaBreakpointUpMd} {
		overflow: hidden;
		border-radius: 2px;
	}
`

export const ShareButtons = styled.div`
	display: flex;
	align-items: center;

	${SocialMediaShareLink} {
		margin-left: 12px;
	}
`

const NewsDetailPage = ({ pageData }: NewsDetailPageProps) => {
	const { isMobile } = useMediaQueryContext()
	const seoData = useSeoContext()
	const isVideo = pageData.__typename === 'Video'
	const isPhotoGallery = pageData.__typename === 'PhotoGallery'

	const thumbnail = {
		src: getRenditionUrl(isMobile ? pageData.thumbnail.small : pageData.thumbnail.medium),
		srcRetina: getRenditionUrl(isMobile ? pageData.thumbnail.medium : pageData.thumbnail.large),
		srcPlaceholder: pageData.thumbnail.placeholderHash,
		alt: pageData.thumbnail.title,
		author: pageData.thumbnail.author,
		caption: pageData.thumbnail.caption,
		loadEagerly: true,
	}

	const getHeader = (
		<NewsDetailHeader
			title={pageData.title}
			category={categoryPageDataMapper(pageData.categoryPage)}
			timestamp={pageData.timestamp || ''}
			author={pageData.author || ''}
			isVideo={isVideo}
			isPhotoGallery={isPhotoGallery}
		/>
	)

	const shareButtons = (
		<ShareButtons>
			<SocialMediaShare network={SocialMediaShareNetworks.facebook} title={pageData.title} url={pageData.url} />
			<SocialMediaShare network={SocialMediaShareNetworks.twitter} title={pageData.title} url={pageData.url} />
		</ShareButtons>
	)

	const body = pageData.body
		.map((block, index) => {
			switch (block.__typename) {
				case 'RichTextBlock':
					return <RichTextBlock key={index} html={block.value} />
				case 'BlockQuoteBlock':
					return <BlockQuoteBlock key={index}>{block.value}</BlockQuoteBlock>
				default:
					return null
			}
		})
		.filter(Boolean)
	if (body.length === 0) {
		body.push(<RichTextBlock key={1} html={`<p>${pageData.description}</p>`} />)
	}

	const mostRecentItems = pageData.mostRecentArticles.map(({ url, title, categoryPage, timestamp }) => ({
		url,
		title,
		category: categoryPageDataMapper(categoryPage),
		timestamp,
	}))

	const mostReadItems = pageData.mostReadArticles.map(({ url, title, categoryPage, timestamp }) => ({
		url,
		title,
		category: categoryPageDataMapper(categoryPage),
		timestamp,
	}))

	const verticalAdvertisementItems = pageData.advertisementsVertical.map(item => {
		return {
			src: getRenditionUrl(isMobile ? item.imageMobile.small : item.imageDesktop.small),
			srcRetina: getRenditionUrl(isMobile ? item.imageMobile.medium : item.imageDesktop.medium),
			srcPlaceholder: isMobile ? item.imageMobile.placeholderHash : item.imageDesktop.placeholderHash,
			alt: item.caption || item.imageMobile.title || item.imageDesktop.title,
			url: item.linkUrl || item.linkPage?.url || '',
		}
	})

	const horizontalAdvertisementItems = pageData.advertisementsHorizontal.map(item => {
		return {
			src: getRenditionUrl(isMobile ? item.imageMobile.small : item.imageDesktop.large),
			srcRetina: getRenditionUrl(isMobile ? item.imageMobile.medium : item.imageDesktop.extraLarge),
			srcPlaceholder: isMobile ? item.imageMobile.placeholderHash : item.imageDesktop.placeholderHash,
			alt: item.caption || item.imageMobile.title || item.imageDesktop.title,
			url: item.linkUrl || item.linkPage?.url || '',
		}
	})

	const relatedNewsLink = {
		label: pageData.relatedArticlesLinkText,
		href: pageData.relatedArticlesLinkPage.url,
	}

	let relatedNewsArticles: JSX.Element[]
	const newsMapper = (article: typeof pageData.relatedArticles[0]) => {
		const { id, title, url, thumbnail, categoryPage, timestamp } = article
		return {
			id,
			url,
			title,
			category: categoryPageDataMapper(categoryPage),
			timestamp: timestamp || '',
			thumbnail: {
				src: getRenditionUrl(!isMobile ? thumbnail.medium : thumbnail.small),
				srcRetina: getRenditionUrl(!isMobile ? thumbnail.large : thumbnail.medium),
				srcPlaceholder: thumbnail.placeholderHash,
				alt: thumbnail.title,
			},
			isRelatedNews: true,
			...(article.__typename === 'Article' && {
				type: article.sliderItems.length > 0 ? NewsCardTypes.photoGallery : NewsCardTypes.simple,
			}),
		}
	}

	let hero: JSX.Element | null = null
	const getHeroSliders = (items: ArticleDetailDataFragment['sliderItems']) =>
		items.map(item => {
			const thumbnail = getSlideThumbnail(item.image, isMobile, true)
			const seoThumbnail = getSlideThumbnail(item.image, false, true)

			if (!seoImages.some(el => el.url === seoThumbnail.src)) {
				seoImages.push({ url: seoThumbnail.src, alt: seoThumbnail.alt })
			}
			return {
				author: item.image.author || '',
				caption: item.caption || item.image.caption || '',
				thumbnail,
			}
		})

	let videoId = ''
	const attachments = pageData.attachments
	const seoImages: OpenGraphImages[] = [
		{
			url: getRenditionUrl(pageData.thumbnail.large),
			alt: pageData.thumbnail.title,
		},
	]

	if (pageData.__typename === 'Article') {
		const slides = getHeroSliders(pageData.sliderItems)
		if (slides.length > 0) {
			hero = (
				<NewsDetailPhotoGalleryHero
					key={pageData.url}
					slides={slides}
					authorImage={thumbnail.author || ''}
					category={categoryPageDataMapper(pageData.categoryPage)}
					socialShare={shareButtons}
				/>
			)
		}
		const mappedArticles = pageData.relatedArticles.map(newsMapper)
		relatedNewsArticles = mappedArticles.map(({ id, ...props }, idx) => <NewsCard key={id} id={id || idx} {...props} />)
	} else if (pageData.__typename === 'PhotoGallery') {
		const slides = getHeroSliders(pageData.sliderItems)
		if (slides.length > 0) {
			hero = (
				<NewsDetailPhotoGalleryHero
					key={pageData.url}
					slides={slides}
					authorImage={thumbnail.author || ''}
					category={categoryPageDataMapper(pageData.categoryPage)}
					socialShare={shareButtons}
				/>
			)
		}
		const mappedArticles = pageData.relatedArticles.map(newsMapper)
		relatedNewsArticles = mappedArticles.map(({ id, ...props }, idx) => (
			<NewsCard key={id} id={id || idx} {...props} type={NewsCardTypes.photoGallery} />
		))
	} else {
		videoId = getYouTubeId(pageData.videoUrl)
		hero = (
			<NewsDetailVideoHero
				key={pageData.url}
				thumbnail={thumbnail}
				videoId={videoId}
				category={categoryPageDataMapper(pageData.categoryPage)}
			/>
		)
		const mappedArticles = pageData.relatedArticles.map(newsMapper)
		relatedNewsArticles = mappedArticles.map(({ id, ...props }, idx) => (
			<NewsCard key={id} id={id || idx} {...props} type={NewsCardTypes.video} />
		))
	}

	const tags = pageData.tags.length > 0 ? pageData.tags : seoData.tags

	return (
		<NewsDetailLayout
			articleType={pageData.__typename}
			hero={hero}
			header={getHeader}
			featuredImage={
				<NewsDetailFeaturedImage>
					<LazyImage {...thumbnail} aspectRatio="730/470" />
				</NewsDetailFeaturedImage>
			}
			authorImage={
				(!!thumbnail.author || !!thumbnail.caption) && (
					<CreditsSpacing>
						<Credits caption={thumbnail.caption} author={thumbnail.author} />
					</CreditsSpacing>
				)
			}
			body={body}
			readSpeaker={<ReadSpeakerPlugin url={pageData.url} />}
			attachments={attachments.length > 0 && <Attachments attachments={attachments} />}
			shareButtons={shareButtons}
			mostRecentTitle={pageData.mostRecentTitle}
			mostRecentItems={mostRecentItems.map((item, index) => MostReadItemRow(item, index))}
			mostReadTitle={pageData.mostReadTitle}
			mostReadItems={mostReadItems.map((item, index) => MostReadItemRow(item, index))}
			verticalAdvertisement={
				verticalAdvertisementItems.length > 0 && (
					<Advertisement items={verticalAdvertisementItems} orientation={OrientationTypes.vertical} />
				)
			}
			horizontalAdvertisement={
				horizontalAdvertisementItems.length > 0 && (
					<Advertisement items={horizontalAdvertisementItems} orientation={OrientationTypes.horizontal} />
				)
			}
			relatedNewsTitle={
				<SectionTitle suffix="related-news-title" link={relatedNewsLink} isSidebar={isVideo || isPhotoGallery}>
					{pageData.relatedArticlesTitle}
				</SectionTitle>
			}
			relatedNewsArticles={relatedNewsArticles}
			relatedNewsLink={
				<PrimaryButton suffix="related-news-link" href={relatedNewsLink.href}>
					{relatedNewsLink.label}
				</PrimaryButton>
			}
			head={
				<NextSeo
					title={pageData.seoTitle || pageData.title}
					titleTemplate={`%s - ${seoData.title}`}
					description={pageData.searchDescription || pageData.description || seoData.description}
					openGraph={{
						title: pageData.seoTitle || pageData.title,
						description: pageData.searchDescription || pageData.description || seoData.description,
						url: pageData.fullUrl || '',
						type: 'article',
						article: {
							publishedTime: pageData.timestamp || '',
							modifiedTime: pageData.lastPublishedAt || '',
							section: pageData.categoryPage.title,
						},
						images: seoImages,
					}}
					twitter={{
						cardType: 'summary_large_image',
					}}
					{...(tags.length > 0 && {
						additionalMetaTags: [
							{
								name: 'keywords',
								content: tags.map(item => item.name).join(', '),
							},
						],
					})}
				/>
			}
		/>
	)
}

export default NewsDetailPage
