import React from 'react'
import styled from 'styled-components'

const SvgFacebookSmallPath = styled.path`
	fill: ${props => props.theme.color.primary};
`

export const SvgFacebookSmall: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string }
> = ({ suffix, ...props }) => {
	const idPrefix = 'svg-facebook-small' + suffix
	return (
		<svg width={8} height={16} aria-labelledby={`${idPrefix}-title`} role="img" fill="none" {...props}>
			<title id={`${idPrefix}-title`}>Facebook</title>
			<SvgFacebookSmallPath d="M.958 8.391h1.41v5.415c0 .107.093.194.208.194h2.391c.115 0 .208-.087.208-.194v-5.39h1.621a.203.203 0 00.206-.17l.248-1.995a.185.185 0 00-.052-.151.215.215 0 00-.155-.065H5.175v-1.25c0-.376.217-.567.646-.567h1.221c.115 0 .208-.087.208-.194V2.195c0-.107-.093-.194-.208-.194H5.36L5.282 2c-.292 0-1.307.053-2.108.741-.889.763-.765 1.675-.736 1.834v1.46H.958c-.115 0-.208.088-.208.194v1.969c0 .107.093.193.208.193z" />
		</svg>
	)
}
