import React from 'react'
import styled from 'styled-components'
import { Tag } from 'components/tag/tag'
import { DateText, DateTextWrapper } from 'components/date-text/date-text'
import { Title } from 'components/title/title'
import { Text } from 'components/text/text'
import { mediaBreakpointUpLg } from 'theme/breakpoints'
import { NewsType, NewsTypes } from 'components/news-type/news-type'

type NewsDetailHeaderProps = {
	title: string
	category: {
		href: string
		label: string
	}
	timestamp: string
	author?: string
	isVideo?: boolean
	isPhotoGallery?: boolean
	isEvent?: boolean
}

const NewsDetailHeaderWrapper = styled.header`
	margin-top: 15px;
	margin-bottom: 0;

	${mediaBreakpointUpLg} {
		margin-top: 19px;
		margin-bottom: 0;
	}
`

const NewsDetailHeaderTitle = styled(Title)`
	margin-top: 0;
	margin-bottom: 6px;
	line-height: 30px;
	${mediaBreakpointUpLg} {
		margin-top: 2px;
		margin-bottom: 6px;
		line-height: 44px;
	}
`

const NewsDetailHeaderMeta = styled.ul`
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	margin: 0;
	list-style: none;
`

const NewsDetailHeaderMetaItem = styled.li`
	position: relative;
	padding: 0;
	margin: 0 0 6px 20px;

	&::before {
		position: absolute;
		top: calc(50% - 3px);
		left: -11px;
		width: 3px;
		height: 3px;
		content: ' ';
		background-color: ${props => props.theme.text.primary};
		border-radius: 50%;
		transform: translateY(-50%);
	}

	&:first-child {
		margin: 0 0 6px 0;

		&::before {
			content: none;
		}
	}

	${DateTextWrapper} {
		font-weight: 500;
		line-height: 19px;
		color: ${props => props.theme.text.primary};

		${mediaBreakpointUpLg} {
			font-size: 18px;
			line-height: 23px;
		}
	}
`

const NewsDetailHeaderAuthor = styled(Text)`
	margin: 0;
	font-size: 16px;
	font-weight: 500;
	line-height: 19px;
	color: ${props => props.theme.text.primary};

	${mediaBreakpointUpLg} {
		font-size: 18px;
		line-height: 23px;
	}
`

const NewsDetailHeaderComponent = ({
	title,
	category,
	timestamp,
	author,
	isEvent,
	isVideo,
	isPhotoGallery,
}: NewsDetailHeaderProps) => {
	const newsType = isVideo ? NewsTypes.video : isPhotoGallery ? NewsTypes.photoGallery : NewsTypes.simple
	return (
		<NewsDetailHeaderWrapper>
			<Tag href={category.href} transparent={true}>
				{category.label}
			</Tag>

			<NewsDetailHeaderTitle size={1}>{title}</NewsDetailHeaderTitle>
			{!isEvent && (
				<NewsDetailHeaderMeta>
					<NewsDetailHeaderMetaItem>
						<DateText timestamp={timestamp} />
					</NewsDetailHeaderMetaItem>
					{author && (
						<NewsDetailHeaderMetaItem>
							<NewsDetailHeaderAuthor>
								<b>{author}</b>
							</NewsDetailHeaderAuthor>
						</NewsDetailHeaderMetaItem>
					)}
					<NewsDetailHeaderMetaItem>
						<NewsType type={newsType} />
					</NewsDetailHeaderMetaItem>
				</NewsDetailHeaderMeta>
			)}
		</NewsDetailHeaderWrapper>
	)
}

export const NewsDetailHeader = React.memo(NewsDetailHeaderComponent)
