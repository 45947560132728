import React, { useState } from 'react'
import styled from 'styled-components'
import YouTube from 'react-youtube'
import { LazyImage } from 'components/lazy-image/lazy-image'
import { SvgPlayLarge } from 'components/svgs/svg-play-large'
import { SvgPlayExtraLarge } from 'components/svgs/svg-play-extra-large'
import { useMediaQueryContext } from 'components/media-query-provider/media-query-provider'
import { Tag } from 'components/tag/tag'
import { transition } from 'theme/utils'

type Props = {
	videoId: string
	thumbnail: {
		src: string
		srcRetina: string
		srcPlaceholder: string
		alt: string
	}
	category: {
		href: string
		label: string
	}
}

export const HeroWrapper = styled.div`
	position: relative;
	overflow: hidden;
	color: ${props => props.theme.colors.white};
	background: ${props => props.theme.colors.black};
`

const VideoWrapper = styled.div<{ show: boolean }>`
	visibility: ${props => (props.show ? 'visible' : 'hidden')};
	opacity: ${props => (props.show ? 1 : 0)};
	transition: ${transition('opacity')};
`

export const AbsoluteWrapper = styled.div<{ show: boolean; removePointerEvents?: boolean }>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: ${props => (props.removePointerEvents ? 'none' : props.show ? 'auto' : 'none')};
	visibility: ${props => (props.show ? 'visible' : 'hidden')};
	opacity: ${props => (props.show ? 1 : 0)};
	transition: ${transition('opacity')};
`

const VideoContainer = styled.div`
	height: 100%;

	div {
		height: 100%;
	}
`

export const HeroHeaderWrapper = styled.div`
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	pointer-events: none;

	.container {
		pointer-events: auto;
	}
`

export const AbsoluteTag = styled(Tag)`
	position: absolute;
	top: 20px;
	left: 20px;
	pointer-events: auto;
`

const HeroPlayButton = styled.button`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 0;
	background: none;
	border: none;

	&:active,
	&:focus {
		outline: none;
	}

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`

export const LazyImageWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
`

export const NewsDetailVideoHero = ({ videoId, thumbnail }: Props) => {
	const { isMobile } = useMediaQueryContext()
	const [isThumbnailVisible, setIsThumbnailVisible] = useState(true)
	const [isHeaderVisible, setIsHeaderVisible] = useState(true)
	const [isVideoVisible, setIsVideoVisible] = useState(false)
	const [player, setPlayer] = useState<YT.Player>()

	const playVideo = () => {
		setIsHeaderVisible(false)
		setIsThumbnailVisible(false)
		setIsVideoVisible(true)
		setTimeout(() => {
			player && player.playVideo()
		}, 200)
	}

	const onVideoReady = (event: { target: YT.Player }) => {
		setPlayer(event.target)
	}

	return (
		<HeroWrapper>
			<VideoWrapper show={isThumbnailVisible}>
				<LazyImageWrapper>
					<LazyImage {...thumbnail} aspectRatio="16/9" />
				</LazyImageWrapper>
			</VideoWrapper>
			<AbsoluteWrapper show={isVideoVisible}>
				<VideoContainer>
					<YouTube
						videoId={videoId}
						opts={{
							height: '100%',
							width: '100%',
							playerVars: { modestbranding: 1, playsinline: 1 },
						}}
						onReady={onVideoReady}
					/>
				</VideoContainer>
			</AbsoluteWrapper>
			<AbsoluteWrapper show={isHeaderVisible}>
				{!!player && (
					<HeroPlayButton onClick={playVideo}>
						{isMobile ? <SvgPlayLarge suffix={`-${videoId}`} /> : <SvgPlayExtraLarge suffix={`-${videoId}`} />}
					</HeroPlayButton>
				)}
			</AbsoluteWrapper>
		</HeroWrapper>
	)
}
