import axios from 'axios'
import { trafficConditionDataMapper } from 'utils/data'

export const dmsiLiveCondition = async (url: string) => {
	try {
		const response = await axios.get(url)
		return trafficConditionDataMapper(response.data)
	} catch (error) {
		throw error
	}
}
