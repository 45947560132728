import React from 'react'
import styled from 'styled-components'

const SvgFacebookMediumPath = styled.path`
	fill: ${props => props.theme.color.primary};
`

export const SvgFacebookMedium: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string }
> = ({ suffix, ...props }) => {
	const idPrefix = 'svg-facebook-medium' + suffix
	return (
		<svg width={16} height={16} aria-labelledby={`${idPrefix}-title`} role="img" fill="none" {...props}>
			<title id={`${idPrefix}-title`}>Facebook</title>
			<SvgFacebookMediumPath d="M4.25 8.67h1.692v6.498c0 .128.111.232.249.232h2.87c.137 0 .249-.104.249-.232V8.7h1.945a.244.244 0 00.248-.206l.295-2.392a.222.222 0 00-.061-.181.257.257 0 00-.186-.078H9.31v-1.5c0-.452.26-.68.776-.68h1.465c.138 0 .249-.105.249-.233V1.234c0-.128-.111-.232-.249-.232h-2.02A2.14 2.14 0 009.44 1c-.35 0-1.569.064-2.53.89-1.067.914-.918 2.01-.883 2.2v1.753H4.25c-.138 0-.249.104-.249.232v2.362c0 .128.111.232.25.232l-.001.001z" />
		</svg>
	)
}
