import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Slider, { Settings, CustomArrowProps } from 'react-slick'
import { useLocationContext } from 'components/location-provider/location-provider'
import { useMediaQueryContext } from 'components/media-query-provider/media-query-provider'
import { ModalAdapter } from 'components/modal-adapter/modal-adapter'
import { SlideItem } from 'components/news-detail-photo-gallery-hero/news-detail-photo-gallery-hero'
import { Text } from 'components/text/text'
import { SvgSlideArrowLeft } from 'components/svgs/svg-slide-arrow-left'
import { SvgClose } from 'components/svgs/svg-close'
import { SvgCloseLarge } from 'components/svgs/svg-close-large'
import { mediaBreakpointUpSm, mediaBreakpointUpLg } from 'theme/breakpoints'
import { resetButtonStyles, resetButtonIconStyles, transition, wordWrap } from 'theme/utils'
import { Language } from 'generated/sdk'
import { LazyImage } from 'components/lazy-image/lazy-image'
import { CreditsSpacing, Credits } from 'components/credits/credits'

type ModalGalleryProps = {
	slides: SlideItem[]
	authorImage: string
	socialShare?: PropTypes.ReactElementLike
	isOpen: boolean
	onClose: () => void
}

export const MODAL_TEXT = {
	previous: {
		[Language.Pt]: 'Imagem Anterior',
		[Language.En]: 'Previous Image',
	},
	next: {
		[Language.Pt]: 'Próxima Imagem',
		[Language.En]: 'Next Image',
	},
	fullScreen: {
		[Language.Pt]: 'Fotogaleria em ecrã inteiro',
		[Language.En]: 'Full-Screen Photo Gallery',
	},
	open: {
		[Language.Pt]: 'Abrir Fotogaleria',
		[Language.En]: 'Open Photo Gallery',
	},
	close: {
		[Language.Pt]: 'Fechar Fotogaleria',
		[Language.En]: 'Close Photo Gallery',
	},
}

const ModalGalleryPrevArrowButton = styled.button`
	left: -55px;
	width: auto;
	height: auto;
	opacity: 0.75;
	transition: ${transition('opacity')};

	&:hover,
	&:focus,
	&:active {
		opacity: 1;
	}

	&::before {
		content: none;
	}
`

const ModalGalleryNextArrowButton = styled.button`
	right: -55px;
	width: auto;
	height: auto;
	opacity: 0.75;
	transition: ${transition('opacity')};

	&:hover,
	&:focus,
	&:active {
		opacity: 1;
	}

	&::before {
		content: none;
	}
`

const ModalGalleryHeader = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	margin: 0 0 8px;
`

const ModalGalleryCounter = styled(Text)`
	margin: 0;
	font-size: 16px;
	font-weight: 500;
	line-height: 1;
	color: ${props => props.theme.colors.white};

	${mediaBreakpointUpLg} {
		font-size: 18px;
	}
`

const ModalGalleryContentWrapper = styled.div`
	margin-right: -15px;
	margin-left: -15px;

	${mediaBreakpointUpSm} {
		margin-right: 0;
		margin-left: 0;
	}
`

const ModalGallerySlide = styled.div`
	position: relative;
`

const ModalGalleryCaption = styled.p`
	padding: 15px;
	margin: 9px 0 0;
	font-size: 14px;
	line-height: 18px;
	color: ${props => props.theme.colors.white};
	${wordWrap}
	${mediaBreakpointUpSm} {
		padding: 0;
	}
	${mediaBreakpointUpLg} {
		font-size: 18px;
		line-height: 26px;
	}
`

const ModalAdapterInner = styled.div`
	padding: 84px 0 17px;

	${mediaBreakpointUpLg} {
		padding: 34px 0;
	}

	@media (min-width: 1440px) {
		[class*='col-'] {
			flex: 0 0 100%;
			max-width: 100%;
			margin-left: 0;
		}
	}
`

const ModalAdapterCloseButton = styled.button`
	${resetButtonIconStyles}
	position: fixed;
	top: 17px;
	right: 16px;
	opacity: 0.75;
	transition: ${transition('opacity')};

	${mediaBreakpointUpLg} {
		top: 34px;
		right: 34px;
	}

	&:hover,
	&:focus {
		${resetButtonStyles}
		opacity: 1;
	}

	svg {
		path {
			stroke: ${props => props.theme.colors.white};
		}
	}
`

const ModalGalleryPrevArrow = (props: CustomArrowProps) => {
	const { className, style, onClick }: CustomArrowProps = props
	const { language } = useLocationContext()

	return (
		<ModalGalleryPrevArrowButton className={className} style={{ ...style }} onClick={onClick}>
			<SvgSlideArrowLeft
				suffix={`-prev-${props.currentSlide || 0}`}
				customTitle={MODAL_TEXT.previous[language]}
				focusable="false"
			/>
		</ModalGalleryPrevArrowButton>
	)
}

const ModalGalleryNextArrow = (props: CustomArrowProps) => {
	const { className, style, onClick }: CustomArrowProps = props
	const { language } = useLocationContext()

	return (
		<ModalGalleryNextArrowButton className={className} style={{ ...style }} onClick={onClick}>
			<SvgSlideArrowLeft
				suffix={`-next-${props.currentSlide || 0}`}
				rotate={180}
				customTitle={MODAL_TEXT.next[language]}
				focusable="false"
			/>
		</ModalGalleryNextArrowButton>
	)
}

export const ModalGallery = ({ slides, authorImage, socialShare, isOpen, onClose }: ModalGalleryProps) => {
	const { language } = useLocationContext()
	const { isMobile } = useMediaQueryContext()
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [slideCurrentIndex, setSlideCurrentIndex] = useState(1)

	useEffect(() => {
		if (isOpen) {
			setModalIsOpen(true)
			document.body.classList.add('no-scroll')
		} else {
			setModalIsOpen(false)
			document.body.classList.remove('no-scroll')
		}
	}, [isOpen])

	const settings: Settings = {
		arrows: true,
		prevArrow: <ModalGalleryPrevArrow />,
		nextArrow: <ModalGalleryNextArrow />,
		afterChange: (current: number) => setSlideCurrentIndex(current + 1),
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
				},
			},
		],
	}

	const closeModal = () => {
		setModalIsOpen(false)
		setSlideCurrentIndex(1)
		onClose()
	}

	return (
		<ModalAdapter
			className="gallery-modal"
			contentLabel={MODAL_TEXT.fullScreen[language]}
			isOpen={modalIsOpen}
			onRequestClose={closeModal}
			shouldCloseOnOverlayClick={true}>
			<ModalAdapterInner>
				<div className="container">
					<div className="row">
						<div className="col-md-10 offset-md-1">
							<ModalGalleryHeader>
								<ModalGalleryCounter>
									{slideCurrentIndex}/{slides.length}
								</ModalGalleryCounter>
								{socialShare && <>{socialShare}</>}
							</ModalGalleryHeader>
							<ModalGalleryContentWrapper>
								<Slider {...settings}>
									{slides.map((slide, index) => {
										const hasAuthor = !!(authorImage || slide.author)

										return (
											<div key={index}>
												<ModalGallerySlide>
													<LazyImage {...slide.thumbnail} aspectRatio="730/470" />
													{hasAuthor && (
														<CreditsSpacing>
															<Credits author={slide.author || authorImage} />
														</CreditsSpacing>
													)}
												</ModalGallerySlide>
												<ModalGalleryCaption>{slide.caption && slide.caption}</ModalGalleryCaption>
											</div>
										)
									})}
								</Slider>
							</ModalGalleryContentWrapper>
						</div>
					</div>
				</div>
			</ModalAdapterInner>
			<ModalAdapterCloseButton onClick={closeModal}>
				{isMobile ? (
					<SvgClose suffix={`${slideCurrentIndex}`} customTitle={MODAL_TEXT.close[language]} focusable="false" />
				) : (
					<SvgCloseLarge suffix={`${slideCurrentIndex}`} customTitle={MODAL_TEXT.close[language]} focusable="false" />
				)}
			</ModalAdapterCloseButton>
		</ModalAdapter>
	)
}
