import React from 'react'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: 'Notícia',
	[Language.En]: 'Article',
}

export const SvgArticleType: React.FC<JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string }> = ({
	suffix,
	...props
}) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-article-type' + suffix

	return (
		<svg width={18} height={18} fill="none" role="img" aria-labelledby={`${idPrefix}-title`} {...props}>
			<title id={`${idPrefix}-title`}>{TITLE_TEXT[language]}</title>
			<path
				d="M16.754 7.518v5.712c0 .631-.504 1.143-1.125 1.143-.85.051-1.126-.571-1.126-1.188v-6.81h1.126c.621 0 1.125.512 1.125 1.143z"
				fill="#0F2F7F"
			/>
			<path
				d="M11.556 2c.92 0 1.666.855 1.666 1.91v9.545c.04.82.465 1.551 1.111 1.909.512.34 1.08.558 1.667.636H3.684C2.176 15.969.976 14.545 1 12.818V3.91C1 2.855 1.747 2 2.667 2h8.89zM9.4 10H4.6c-.33 0-.6.224-.6.5s.27.5.6.5h4.8c.331 0 .6-.224.6-.5s-.269-.5-.6-.5zm0-2H4.6c-.33 0-.6.224-.6.5s.27.5.6.5h4.8c.331 0 .6-.224.6-.5S9.731 8 9.4 8zm0-2H4.6c-.33 0-.6.224-.6.5s.27.5.6.5h4.8c.331 0 .6-.224.6-.5S9.731 6 9.4 6z"
				fill="#0F2F7F"
			/>
		</svg>
	)
}
