import React from 'react'
import { Language } from 'generated/sdk'
import { useLocationContext } from 'components/location-provider/location-provider'

const TITLE_TEXT = {
	[Language.Pt]: (inverted = false) => (inverted ? 'Seta para cima' : 'Seta para baixo'),
	[Language.En]: (inverted = false) => (inverted ? 'Arrow up' : 'Arrow down'),
}

export const SvgArrowDownCircle: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, rotate = 0, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-arrow-right' + suffix

	return (
		<svg
			width={20}
			height={20}
			fill="none"
			className="svg-arrow-down-circle"
			aria-labelledby={`${idPrefix}-title`}
			role="img"
			{...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language](rotate === 180)}</title>
			<path
				className="svg-arrow-down-circle__circle"
				d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0z"
				fill="#fff"
			/>
			<path
				transform={`rotate(${rotate} 10 10)`}
				d="M6 8l4 4 4-4"
				stroke="#000"
				strokeWidth={1.2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
