import React from 'react'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: 'Pino de localização',
	[Language.En]: 'Location pin',
}

export const SvgLocationPin: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, stroke = '#0F2F7F', ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-location-pin' + suffix

	return (
		<svg width={24} height={24} aria-labelledby={`${idPrefix}-title`} role="img" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language]}</title>
			<g fill="none" fillRule="evenodd" stroke={stroke} strokeWidth={1.5} transform="translate(1 3)">
				<path d="M11.183 16.263l4.677-7.654a5.162 5.162 0 00-.755-6.346A5.149 5.149 0 0011.452.75h-.904a5.165 5.165 0 00-4.414 7.848l5.049 7.665z" />
				<circle cx={11} cy={6} r={2.25} />
			</g>
		</svg>
	)
}
