import React from 'react'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: 'Vídeo',
	[Language.En]: 'Video',
}

export const SvgVideoType: React.FC<JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string }> = ({
	suffix,
	...props
}) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-video-type' + suffix

	return (
		<svg width={18} height={18} fill="none" role="img" aria-labelledby={`${idPrefix}-title`} {...props}>
			<title id={`${idPrefix}-title`}>{TITLE_TEXT[language]}</title>
			<path
				d="M9 1.5a7.5 7.5 0 11-.001 15.001A7.5 7.5 0 019 1.5zM7.815 6.68a.243.243 0 00-.244.243v4.154a.243.243 0 00.366.21l3.56-2.077a.243.243 0 000-.42l-3.56-2.077a.243.243 0 00-.122-.033z"
				fill="#0F2F7F"
			/>
		</svg>
	)
}
