import React from 'react'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: 'Fotogaleria',
	[Language.En]: 'Photo gallery',
}

export const SvgPhotoGalleryType: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string }
> = ({ suffix, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-photo-gallery-type' + suffix

	return (
		<svg width={18} height={18} fill="none" role="img" aria-labelledby={`${idPrefix}-title`} {...props}>
			<title id={`${idPrefix}-title`}>{TITLE_TEXT[language]}</title>
			<path
				d="M13.347 11h2.023c.19 0 .344-.162.344-.36V3.36A.353.353 0 0015.37 3H5.773a.353.353 0 00-.344.36v2.116h7.145c.426 0 .773.363.773.811V11z"
				fill="#0F2F7F"
			/>
			<path
				d="M2 6.66v7.305c0 .217.176.393.393.393h9.66a.393.393 0 00.393-.393V6.661a.392.392 0 00-.392-.394H2.393A.393.393 0 002 6.661z"
				fill="#0F2F7F"
			/>
		</svg>
	)
}
