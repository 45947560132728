import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { LazyImage } from 'components/lazy-image/lazy-image'
import { HeroWrapper, LazyImageWrapper } from 'components/news-detail-video-hero/news-detail-video-hero'
import Slider, { Settings } from 'react-slick'
import { SliderBlockPrevArrow, SliderBlockNextArrow, SliderBlockIconButton } from 'components/slider-block/slider-block'
import { ModalGallery } from 'components/modal-gallery/modal-gallery'
import { mediaBreakpointUpLg } from 'theme/breakpoints'
import { transition } from 'theme/utils'
import { Credits, CreditsSpacing } from 'components/credits/credits'

export type SlideItem = {
	author?: string
	thumbnail: {
		src: string
		srcRetina: string
		srcPlaceholder: string
		alt: string
	}
	caption?: string
}

type NewsDetailPhotoGalleryHeroProps = {
	settings?: Settings
	slides: SlideItem[]
	authorImage: string
	category: {
		href: string
		label: string
	}
	socialShare: PropTypes.ReactElementLike
}

const PhotoGalleryWrapper = styled.div<{ show: boolean; removePointerEvents?: boolean }>`
	visibility: ${props => (props.show ? 'visible' : 'hidden')};
	opacity: ${props => (props.show ? 1 : 0)};
	transition: ${transition('opacity')};
`

const PhotoGallerySlide = styled.div`
	position: relative;
	display: block !important;
`

const SliderIconButton = styled(SliderBlockIconButton)`
	top: 12px;
	right: 16px;

	${mediaBreakpointUpLg} {
		top: 31px;
		right: 23px;
		padding: 10px;
	}
`

const ModalPrevArrow = styled(SliderBlockPrevArrow)`
	top: 50%;
	left: 12px;
	transform: translate(0, -50%);

	${mediaBreakpointUpLg} {
		left: 30px;
	}
`

const ModalNextArrow = styled(SliderBlockNextArrow)`
	top: 50%;
	right: 12px;
	transform: translate(0, -50%);

	${mediaBreakpointUpLg} {
		right: 30px;
	}
`

export const NewsDetailPhotoGalleryHero = ({
	settings,
	slides,
	authorImage,
	socialShare,
}: NewsDetailPhotoGalleryHeroProps) => {
	const [modalIsOpen, setModalIsOpen] = useState(false)

	const sliderSettings: Settings = {
		...settings,
		arrows: true,
		prevArrow: <ModalPrevArrow />,
		nextArrow: <ModalNextArrow />,
		responsive: [
			{
				breakpoint: 576,
				settings: {
					arrows: false,
				},
			},
		],
		autoplay: true,
		autoplaySpeed: 5500,
	}

	return (
		<HeroWrapper>
			<PhotoGalleryWrapper show>
				<LazyImageWrapper>
					<Slider {...sliderSettings} lazyLoad="progressive">
						{slides.map((slide, index) => {
							const hasAuthor = !!(authorImage || slide.author)
							return (
								<PhotoGallerySlide key={index}>
									<LazyImage {...slide.thumbnail} loadEagerly aspectRatio="730/470" />
									{hasAuthor && (
										<CreditsSpacing>
											<Credits author={slide.author || authorImage} />
										</CreditsSpacing>
									)}
								</PhotoGallerySlide>
							)
						})}
					</Slider>
					<SliderIconButton clickHandler={() => setModalIsOpen(true)} />
				</LazyImageWrapper>
			</PhotoGalleryWrapper>

			<ModalGallery
				slides={slides}
				authorImage={authorImage}
				socialShare={socialShare}
				isOpen={modalIsOpen}
				onClose={() => setModalIsOpen(false)}
			/>
		</HeroWrapper>
	)
}
