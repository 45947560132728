import React from 'react'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: 'Fechar',
	[Language.En]: 'Close',
}

export const SvgCloseLarge: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, stroke, rotate, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-close-large' + suffix

	return (
		<svg width={24} height={24} role="img" aria-labelledby={`${idPrefix}-title`} {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language]}</title>
			<path
				fill="#FFF"
				d="M13.897 11.808l9.287-9.286A1.477 1.477 0 1021.095.433L11.808 9.72 2.521.433A1.476 1.476 0 10.433 2.522l9.287 9.286-9.287 9.287a1.477 1.477 0 102.088 2.088l9.287-9.287 9.287 9.287a1.473 1.473 0 002.089 0 1.476 1.476 0 000-2.088l-9.287-9.287z"
			/>
		</svg>
	)
}
