import React from 'react'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: 'Reproduzir',
	[Language.En]: 'Play',
}

export const SvgPlayExtraSmall: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-play-extra-small' + suffix

	return (
		<svg width={16} height={16} fill="none" aria-labelledby={`${idPrefix}-title`} role="img" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language]}</title>
			<g filter={`url(#${idPrefix}-filter0_d)`} clipPath={`url(#${idPrefix}-clip0)`}>
				<path
					d="M8 1.333a6.667 6.667 0 11-.001 13.335A6.667 6.667 0 018 1.333zM6.946 5.938a.216.216 0 00-.216.216v3.692a.216.216 0 00.325.187l3.165-1.846a.216.216 0 000-.374L7.055 5.967a.216.216 0 00-.109-.03z"
					fill="#fff"
				/>
			</g>
			<defs>
				<clipPath id={`${idPrefix}-clip0`}>
					<path fill="#fff" d="M0 0h16v16H0z" />
				</clipPath>
				<filter
					id={`${idPrefix}-filter0_d`}
					x={0.333}
					y={1.333}
					width={15.333}
					height={15.333}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB">
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy={1} />
					<feGaussianBlur stdDeviation={0.5} />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
				</filter>
			</defs>
		</svg>
	)
}
