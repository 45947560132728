import React from 'react'
import styled from 'styled-components'
import { Text } from 'components/text/text'

type EmptyResultsProps = {
	icon: React.ReactNode
	message: string
}

const EmptyResultsWrapper = styled.div`
	display: block;
	margin: 0 auto;
	text-align: center;
`

const EmptyResultsMessage = styled(Text)`
	margin: 3px 0 0;
	font-size: 16px;
	font-weight: 700;
	line-height: 20px;
	color: ${props => props.theme.text.primary};
	text-align: center;
`

export const EmptyResults = ({ icon, message }: EmptyResultsProps) => {
	return (
		<EmptyResultsWrapper>
			{icon}
			<EmptyResultsMessage dangerouslySetInnerHTML={{ __html: message }} />
		</EmptyResultsWrapper>
	)
}
