import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Slider, { Settings, CustomArrowProps } from 'react-slick'
import { useLocationContext } from 'components/location-provider/location-provider'
import { useMediaQueryContext } from 'components/media-query-provider/media-query-provider'
import { mediaBreakpointUpSm, mediaBreakpointUpLg, mediaBreakpointUpXl } from 'theme/breakpoints'
import { ModalGallery, MODAL_TEXT } from 'components/modal-gallery/modal-gallery'
import { SvgSlideArrowLeft } from 'components/svgs/svg-slide-arrow-left'
import { SvgSlideArrowLeftMedium } from 'components/svgs/svg-slide-arrow-left-medium'
import { SvgPhotoGallery } from 'components/svgs/svg-photo-gallery'
import { SvgPhotoGalleryMedium } from 'components/svgs/svg-photo-gallery-medium'
import { rgbaWithHex, transition } from 'theme/utils'
import { SlideItem } from 'components/news-detail-photo-gallery-hero/news-detail-photo-gallery-hero'
import { LazyImage } from 'components/lazy-image/lazy-image'

type SliderBlockProps = {
	settings?: Settings
	slides: SlideItem[]
	authorImage: string
	socialShare?: PropTypes.ReactElementLike
}

export const slideArrowCss = (position: string, value = 20) => {
	const arrowPosition = `${position}: ${value}px;`

	return css`
		${arrowPosition}
		width: auto;
		height: auto;
		opacity: 0.75;
		transition: ${transition('opacity')};

		&:hover,
		&:focus,
		&:active {
			opacity: 1;
		}

		&::before {
			content: none;
		}
	`
}

const SliderBlockWrapper = styled.div`
	margin: 32px 0;
`

const SliderBlockCarousel = styled.div`
	position: relative;
	width: calc(100% + 30px);
	margin-right: -15px;
	margin-left: -15px;
	border-radius: 0.9px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);

	${mediaBreakpointUpSm} {
		width: auto;
		margin-right: 0;
		margin-left: 0;
	}

	${mediaBreakpointUpLg} {
		border-radius: 2px;
	}

	.slick-slide {
		font-size: 0; /* Fix incorrect height */
	}
`

const SliderBlockIconButtonWrapper = styled.button`
	position: absolute;
	top: 11px;
	right: 11px;
	padding: 4px;
	${props => rgbaWithHex(props.theme.colors.black, 0.5, 'background')}
	font-size: 0;
	line-height: 0;
	border: solid 1px ${props => props.theme.colors.white};
	border-radius: 2px;
	transition: ${transition('background')};

	${mediaBreakpointUpLg} {
		top: 15px;
		right: 15px;
		padding: 11px;
	}

	&:hover,
	&:focus {
		${props => rgbaWithHex(props.theme.colors.black, 0.7, 'background')}
		outline: 0;
	}
`

const SliderBlockPrevArrowButton = styled.button`
	z-index: 5;
	${slideArrowCss('left', 20)}
	${mediaBreakpointUpLg} {
		top: 38%;
	}

	${mediaBreakpointUpXl} {
		top: 46%;
	}
`

const SliderBlockNextArrowButton = styled.button`
	z-index: 5;
	${slideArrowCss('right', 20)}
	${mediaBreakpointUpLg} {
		top: 38%;
	}

	${mediaBreakpointUpXl} {
		top: 46%;
	}
`

export const SliderBlockPrevArrow = (props: CustomArrowProps) => {
	const { className, style, onClick }: CustomArrowProps = props
	const { language } = useLocationContext()
	const { isMobile } = useMediaQueryContext()

	return (
		<SliderBlockPrevArrowButton className={className} style={{ ...style }} onClick={onClick}>
			{isMobile ? (
				<SvgSlideArrowLeftMedium
					suffix="slider-block-left"
					customTitle={MODAL_TEXT.previous[language]}
					focusable="false"
				/>
			) : (
				<SvgSlideArrowLeft suffix="slider-block-left" customTitle={MODAL_TEXT.previous[language]} focusable="false" />
			)}
		</SliderBlockPrevArrowButton>
	)
}

export const SliderBlockNextArrow = (props: CustomArrowProps) => {
	const { className, style, onClick }: CustomArrowProps = props
	const { language } = useLocationContext()
	const { isMobile } = useMediaQueryContext()

	return (
		<SliderBlockNextArrowButton className={className} style={{ ...style }} onClick={onClick}>
			{isMobile ? (
				<SvgSlideArrowLeftMedium
					suffix="slider-block-right"
					rotate={180}
					customTitle={MODAL_TEXT.next[language]}
					focusable="false"
				/>
			) : (
				<SvgSlideArrowLeft
					suffix="slider-block-right"
					rotate={180}
					customTitle={MODAL_TEXT.next[language]}
					focusable="false"
				/>
			)}
		</SliderBlockNextArrowButton>
	)
}

export const SliderBlockIconButton = (props: { className?: string; clickHandler: () => void }) => {
	const { language } = useLocationContext()
	const { isMobile } = useMediaQueryContext()

	return (
		<SliderBlockIconButtonWrapper className={props.className} onClick={props.clickHandler}>
			{isMobile ? (
				<SvgPhotoGalleryMedium suffix="-slider-block" customTitle={MODAL_TEXT.open[language]} focusable="false" />
			) : (
				<SvgPhotoGallery suffix="-slider-block" customTitle={MODAL_TEXT.open[language]} focusable="false" />
			)}
		</SliderBlockIconButtonWrapper>
	)
}

export const SliderBlock = ({ settings, authorImage, slides, socialShare }: SliderBlockProps) => {
	const [modalIsOpen, setModalIsOpen] = useState(false)

	const sliderSettings = {
		...settings,
		arrows: true,
		prevArrow: <SliderBlockPrevArrow />,
		nextArrow: <SliderBlockNextArrow />,
	}

	return (
		<SliderBlockWrapper>
			<SliderBlockCarousel>
				<Slider {...sliderSettings}>
					{slides.map((slide, index) => (
						<div key={index}>
							<LazyImage {...slide.thumbnail} aspectRatio="730/470" />
						</div>
					))}
				</Slider>
				<SliderBlockIconButton clickHandler={() => setModalIsOpen(true)} />
			</SliderBlockCarousel>

			<ModalGallery
				authorImage={authorImage}
				slides={slides}
				socialShare={socialShare}
				isOpen={modalIsOpen}
				onClose={() => setModalIsOpen(false)}
			/>
		</SliderBlockWrapper>
	)
}
