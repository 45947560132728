import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQueryContext } from 'components/media-query-provider/media-query-provider'
import { SectionLayout } from 'components/section-layout/section-layout'
import { ArticleDetailDataFragment, PhotoGalleryDataFragment, VideoDataFragment } from 'generated/sdk'
import { Tabs, TabsItem } from 'components/tabs/tabs'
import styled from 'styled-components'
import { mediaBreakpointUpSm, mediaBreakpointUpLg } from 'theme/breakpoints'

export type NewsDetailArticleType =
	| ArticleDetailDataFragment['__typename']
	| PhotoGalleryDataFragment['__typename']
	| VideoDataFragment['__typename']

type NewsDetailLayoutProps = {
	articleType: NewsDetailArticleType
	hero?: PropTypes.ReactNodeLike
	header: PropTypes.ReactNodeLike
	featuredImage: PropTypes.ReactNodeLike
	authorImage: PropTypes.ReactNodeLike
	body: PropTypes.ReactNodeLike[]
	readSpeaker: PropTypes.ReactNodeLike
	attachments: PropTypes.ReactNodeLike
	shareButtons: PropTypes.ReactNodeLike
	mostRecentTitle: string
	mostRecentItems: PropTypes.ReactNodeLike[]
	mostReadTitle: string
	mostReadItems: PropTypes.ReactNodeLike[]
	verticalAdvertisement: PropTypes.ReactNodeLike
	horizontalAdvertisement: PropTypes.ReactNodeLike
	relatedNewsTitle: PropTypes.ReactNodeLike
	relatedNewsArticles: PropTypes.ReactNodeLike[]
	relatedNewsLink: PropTypes.ReactNodeLike
	head: PropTypes.ReactNodeLike
}

const NewsDetailFeaturedImageWrapper = styled.div`
	position: relative;
	margin-right: -15px;
	margin-left: -15px;

	${mediaBreakpointUpSm} {
		margin-right: 0;
		margin-left: 0;
	}
`

const NewsDetailBody = styled.div`
	padding-top: 10px;

	${mediaBreakpointUpLg} {
		padding-top: 14px;
		padding-bottom: 0;
	}
`

const NewsDetailReadSpeakerShareButtons = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 12px 0;

	${mediaBreakpointUpLg} {
		margin: 13px 0 15px;
	}
`

const NewsDetailShareButtonsBottom = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin: 32px 0 0;

	${mediaBreakpointUpLg} {
		margin: 39px 0 0;
	}
`

const NewsDetailRelatedNewsBody = styled.div`
	margin-top: 38px;

	${mediaBreakpointUpLg} {
		margin-top: 25px;
	}
`

const NewsDetailTabsWrapper = styled.div`
	${mediaBreakpointUpLg} {
		margin-top: -14px;
	}
`

const NewsDetailHorizontalAd = styled(SectionLayout)`
	${mediaBreakpointUpLg} {
		padding-top: 84px;
		padding-bottom: 48px;
	}
`

const NewsDetailRelatedNews = styled(SectionLayout)`
	padding-top: 30px;
	padding-bottom: 16px;

	${mediaBreakpointUpLg} {
		padding: 0 0 100px;
	}
`

export const NewsDetailLayout = ({
	articleType,
	header,
	featuredImage,
	authorImage,
	hero,
	body,
	readSpeaker,
	attachments,
	shareButtons,
	mostRecentTitle,
	mostRecentItems,
	mostReadTitle,
	mostReadItems,
	verticalAdvertisement,
	horizontalAdvertisement,
	relatedNewsTitle,
	relatedNewsArticles,
	relatedNewsLink,
	head,
}: NewsDetailLayoutProps) => {
	const { isMobile } = useMediaQueryContext()
	const isSimpleArticle = articleType === 'Article'
	const isVideo = articleType === 'Video'
	const isPhotoGallery = articleType === 'PhotoGallery'

	const mostRecentAndReadTabItems: TabsItem[] = [
		{
			title: mostRecentTitle,
			content: mostRecentItems,
		},
		{
			title: mostReadTitle,
			content: mostReadItems,
		},
	]

	const relatedArticlesToShow = isSimpleArticle ? 3 : isMobile ? 4 : 6
	const relatedArticles = relatedNewsArticles.slice(0, relatedArticlesToShow)

	return (
		<>
			{head}
			<SectionLayout noPaddingTop noPaddingBottom>
				<div className="container">
					<div className="row">
						<div className="col-md-10 offset-md-1 col-lg-8 offset-lg-0">{header}</div>
					</div>
					<NewsDetailBody className="row">
						<div className="col-md-10 offset-md-1 col-lg-8 offset-lg-0">
							<NewsDetailFeaturedImageWrapper>
								{hero ? (
									hero
								) : (
									<>
										{featuredImage}
										{authorImage}
									</>
								)}
							</NewsDetailFeaturedImageWrapper>
							<NewsDetailReadSpeakerShareButtons>
								{readSpeaker}
								{shareButtons}
							</NewsDetailReadSpeakerShareButtons>
							<div id="js-read-speaker">{body}</div>
							{attachments}
							{isSimpleArticle && <NewsDetailShareButtonsBottom>{shareButtons}</NewsDetailShareButtonsBottom>}
							{/* related news (video or photo gallery) section */}
							{(isVideo || isPhotoGallery) && relatedArticles.length > 0 && (
								<NewsDetailRelatedNewsBody>
									<div className="mb-xs-24 mb-26">{relatedNewsTitle}</div>
									<div className="row">
										{relatedArticles.map((newsItem, index) => (
											<div key={index} className="col-lg-6 mb-xs-24 mb-44">
												{newsItem}
											</div>
										))}
										{isMobile && (
											<div className="col-12 mt-xs-3">
												<div>{relatedNewsLink}</div>
											</div>
										)}
									</div>
								</NewsDetailRelatedNewsBody>
							)}
						</div>
						{!isMobile && (
							<div className="col-lg-4">
								<div className="row">
									<div className="col-12">
										<NewsDetailTabsWrapper className="mb-30">
											<Tabs tabs={mostRecentAndReadTabItems} />
										</NewsDetailTabsWrapper>
									</div>
									{verticalAdvertisement && (
										<div className="col-12">
											<div>{verticalAdvertisement}</div>
										</div>
									)}
								</div>
							</div>
						)}
					</NewsDetailBody>
				</div>
			</SectionLayout>

			{/* horizontal advertisement section */}
			{!isMobile && horizontalAdvertisement && (
				<NewsDetailHorizontalAd lazy lazyHeights={{ xs: 330, xl: 370 }}>
					<div className="container">
						<div className="row">
							<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0">{horizontalAdvertisement}</div>
						</div>
					</div>
				</NewsDetailHorizontalAd>
			)}

			{/* related news (simple) section */}
			{isSimpleArticle && relatedArticles.length > 0 && (
				<NewsDetailRelatedNews lazy lazyHeights={{ xs: 450, lg: 450, xl: 490 }}>
					<div className="container">
						<div className="row">
							<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0 mb-xs-24 mb-32">{relatedNewsTitle}</div>
							{relatedArticles.map((newsItem, index) => (
								<div key={index} className="col-md-10 offset-md-1 col-lg-4 offset-lg-0 mb-xs-24">
									{newsItem}
								</div>
							))}
							{isMobile && (
								<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0 mt-xs-12">
									<div>{relatedNewsLink}</div>
								</div>
							)}
						</div>
					</div>
				</NewsDetailRelatedNews>
			)}
			{isMobile && (
				<NewsDetailRelatedNews lazy lazyHeights={{ xs: 550 }}>
					<div className="container">
						<div className="row">
							{verticalAdvertisement && (
								<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0 mb-xs-24">{verticalAdvertisement}</div>
							)}
							<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0 mb-xs-31">
								<NewsDetailTabsWrapper>
									<Tabs tabs={mostRecentAndReadTabItems} />
								</NewsDetailTabsWrapper>
							</div>
							{horizontalAdvertisement && (
								<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0">{horizontalAdvertisement}</div>
							)}
						</div>
					</div>
				</NewsDetailRelatedNews>
			)}
		</>
	)
}
