import React from 'react'
import { NextSeo } from 'next-seo'
import { EventDetailDataFragment } from 'generated/sdk'
import { useMediaQueryContext } from 'components/media-query-provider/media-query-provider'
import { useSeoContext } from 'components/seo-provider/seo-provider'
import { EventDetailLayout } from 'components/layouts/event-detail-layout/event-detail-layout'
import { NewsDetailHeader } from 'components/news-detail-header/news-detail-header'
import { NewsDetailFeaturedImage, ShareButtons } from 'components/news-detail-page/news-detail-page'
import { LazyImage } from 'components/lazy-image/lazy-image'
import { ReadSpeakerPlugin } from 'components/read-speaker-plugin/read-speaker-plugin'
import { RichTextBlock } from 'components/rich-text-block/rich-text-block'
import { BlockQuoteBlock } from 'components/block-quote-block/block-quote-block'
import { SocialMediaShare, SocialMediaShareNetworks } from 'components/social-media-share/social-media-share'
import { EventInformation } from 'components/event-information/event-information'
import { Advertisement, OrientationTypes } from 'components/advertisement/advertisement'
import { categoryPageDataMapper, getRenditionUrl } from 'utils/data'
import { CreditsSpacing, Credits } from 'components/credits/credits'

type EventDetailPageProps = {
	pageData: EventDetailDataFragment
}

const EventDetailPage = ({ pageData }: EventDetailPageProps) => {
	const { isMobile } = useMediaQueryContext()
	const seoData = useSeoContext()

	const thumbnail = {
		src: getRenditionUrl(isMobile ? pageData.thumbnail.small : pageData.thumbnail.medium),
		srcRetina: getRenditionUrl(isMobile ? pageData.thumbnail.medium : pageData.thumbnail.large),
		srcPlaceholder: pageData.thumbnail.placeholderHash,
		alt: pageData.thumbnail.title,
		author: pageData.thumbnail.author,
	}

	const getHeader = () => (
		<NewsDetailHeader
			title={pageData.title}
			category={categoryPageDataMapper(pageData.categoryPage)}
			timestamp={pageData.timestamp || ''}
			isEvent={true}
		/>
	)

	const shareButtons = (
		<ShareButtons>
			<SocialMediaShare network={SocialMediaShareNetworks.facebook} title={pageData.title} url={pageData.url} />
			<SocialMediaShare network={SocialMediaShareNetworks.twitter} title={pageData.title} url={pageData.url} />
		</ShareButtons>
	)

	const body = pageData.body
		.map((block, index) => {
			switch (block.__typename) {
				case 'RichTextBlock':
					return <RichTextBlock key={index} html={block.value} />
				case 'BlockQuoteBlock':
					return <BlockQuoteBlock key={index}>{block.value}</BlockQuoteBlock>
				default:
					return null
			}
		})
		.filter(Boolean)
	if (body.length === 0) {
		body.push(<RichTextBlock key={1} html={`<p>${pageData.description}</p>`} />)
	}

	const verticalAdvertisementItems = pageData.advertisementsVertical.map(item => {
		return {
			src: getRenditionUrl(isMobile ? item.imageMobile.small : item.imageDesktop.small),
			srcRetina: getRenditionUrl(isMobile ? item.imageMobile.medium : item.imageDesktop.medium),
			srcPlaceholder: isMobile ? item.imageMobile.placeholderHash : item.imageDesktop.placeholderHash,
			alt: item.caption || item.imageMobile.title || item.imageDesktop.title,
			url: item.linkUrl || item.linkPage?.url || '',
		}
	})

	const horizontalAdvertisementItems = pageData.advertisementsHorizontal.map(item => {
		return {
			src: getRenditionUrl(isMobile ? item.imageMobile.small : item.imageDesktop.large),
			srcRetina: getRenditionUrl(isMobile ? item.imageMobile.medium : item.imageDesktop.extraLarge),
			srcPlaceholder: isMobile ? item.imageMobile.placeholderHash : item.imageDesktop.placeholderHash,
			alt: item.caption || item.imageMobile.title || item.imageDesktop.title,
			url: item.linkUrl || item.linkPage?.url || '',
		}
	})

	const tags = pageData.tags.length > 0 ? pageData.tags : seoData.tags

	return (
		<EventDetailLayout
			header={getHeader()}
			featuredImage={
				<NewsDetailFeaturedImage>
					<LazyImage {...thumbnail} aspectRatio="730/470" />
				</NewsDetailFeaturedImage>
			}
			authorImage={
				!!thumbnail.author && (
					<CreditsSpacing>
						<Credits author={thumbnail.author} />
					</CreditsSpacing>
				)
			}
			body={body}
			readSpeaker={<ReadSpeakerPlugin url={pageData.url} />}
			shareButtons={shareButtons}
			eventInformation={<EventInformation dates={pageData.dates} locations={pageData.locations} />}
			verticalAdvertisement={
				verticalAdvertisementItems.length > 0 && (
					<Advertisement items={verticalAdvertisementItems} orientation={OrientationTypes.vertical} />
				)
			}
			horizontalAdvertisement={
				horizontalAdvertisementItems.length > 0 && (
					<Advertisement items={horizontalAdvertisementItems} orientation={OrientationTypes.horizontal} />
				)
			}
			head={
				<NextSeo
					title={pageData.seoTitle || pageData.title}
					titleTemplate={`%s - ${seoData.title}`}
					description={pageData.searchDescription || pageData.description || seoData.description}
					openGraph={{
						title: pageData.seoTitle || pageData.title,
						description: pageData.searchDescription || pageData.description || seoData.description,
						url: pageData.fullUrl || '',
						type: 'article',
						article: {
							publishedTime: pageData.timestamp || '',
							modifiedTime: pageData.lastPublishedAt || '',
							section: pageData.categoryPage.title,
						},
						images: [
							{
								url: pageData.thumbnail.large?.url || '',
								alt: pageData.thumbnail.title,
							},
						],
					}}
					twitter={{
						cardType: 'summary_large_image',
					}}
					{...(tags.length > 0 && {
						additionalMetaTags: [
							{
								name: 'keywords',
								content: tags.map(item => item.name).join(', '),
							},
						],
					})}
				/>
			}
		/>
	)
}

export default EventDetailPage
