import React from 'react'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: (inverted = false) => (inverted ? 'Slide seguinte' : 'Slide anterior'),
	[Language.En]: (inverted = false) => (inverted ? 'Next slide' : 'Previous slide'),
}

export const SvgSlideArrowLeft: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, rotate = 0, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-slide-arrow-left' + suffix

	return (
		<svg width={26} height={44} aria-labelledby={`${idPrefix}-title`} role="img" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language](rotate === 180)}</title>
			<path
				fill="none"
				stroke="#FFF"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={4}
				d="M24 42L2 22 24 2"
				transform={`rotate(${rotate} 13 22)`}
			/>
		</svg>
	)
}
