import React from 'react'
import styled from 'styled-components'
import { Text, TextTypes, TextWeights } from 'components/text/text'
import { mediaBreakpointUpLg } from 'theme/breakpoints'
import { wordWrap } from 'theme/utils'
import { mainGradient } from 'theme/utils'

type CreditsProps = {
	caption?: string | null
	author?: string | null
}

type CreditsWrapperProps = {
	hasCaption: boolean
}

export const CreditsSpacing = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: flex-end;
	pointer-events: none;
`

const CreditsWrapper = styled.div<CreditsWrapperProps>`
	display: flex;
	align-items: flex-end;
	width: 100%;
	height: 60%;
	padding-right: 12px;
	padding-bottom: 12px;
	padding-left: 12px;
	${props => props.hasCaption && mainGradient(true, props.theme.colors.nightBlue)}
`

const CreditsContentWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`

const CreditsCaptionWrapper = styled.div`
	align-self: center;
	margin-right: 12px;
	pointer-events: all;
`

const CreditsCaption = styled(Text)`
	margin: 0;
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
	color: ${props => props.theme.colors.white};
	letter-spacing: 0;
	${wordWrap}
	${mediaBreakpointUpLg} {
		font-size: 15px;
		line-height: 20px;
	}
`

const CreditsAuthorWrapper = styled.div`
	display: inline-block;
	flex-shrink: 0;
	align-self: flex-end;
	padding: 7px 12px 5px 12px;
	pointer-events: all;
	background-color: rgba(0, 0, 0, 0.4);
	border-radius: 18px;
	backdrop-filter: blur(16px);

	${mediaBreakpointUpLg} {
		padding: 7px 16px 5px 16px;
	}
`

const CreditsAuthor = styled(Text)`
	margin: 0;
	font-size: 14px;
	line-height: 24px;
	color: ${props => props.theme.colors.white};
`

export const Credits = ({ caption, author }: CreditsProps) => (
	<CreditsWrapper hasCaption={!!caption}>
		<CreditsContentWrapper>
			<CreditsCaptionWrapper>{caption && <CreditsCaption>{caption}</CreditsCaption>}</CreditsCaptionWrapper>
			<CreditsAuthorWrapper>
				{author && (
					<CreditsAuthor weight={TextWeights.bold} textType={TextTypes.paragraph}>
						{author}
					</CreditsAuthor>
				)}
			</CreditsAuthorWrapper>
		</CreditsContentWrapper>
	</CreditsWrapper>
)
