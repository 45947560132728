import React from 'react'
import { NewsCardTypes, NewsCardSizes } from 'components/news-card/news-card'
import { LazyImage } from 'components/lazy-image/lazy-image'
import { Tag, TagLinkText } from 'components/tag/tag'
import { Title } from 'components/title/title'
import { DateText } from 'components/date-text/date-text'
import styled, { ThemeProvider } from 'styled-components'
import { positionAbsoluteAndStretch, boxShadowRgbaWithHex, rgbaWithHex, transition, mainGradient } from 'theme/utils'
import { darkTheme, lightTheme } from 'theme/theme'
import { Paragraph } from 'components/text/text'
import { SvgPhotoGallery } from 'components/svgs/svg-photo-gallery'
import { SvgPlaySmall } from 'components/svgs/svg-play-small'
import { SvgPlayMedium } from 'components/svgs/svg-play-medium'
import { SvgPlayLarge } from 'components/svgs/svg-play-large'
import { SvgPlayExtraLarge } from 'components/svgs/svg-play-extra-large'
import { mediaBreakpointUpSm, mediaBreakpointUpLg } from 'theme/breakpoints'
import { useMediaQueryContext } from 'components/media-query-provider/media-query-provider'
import { NextLink } from 'components/next-link/next-link'

const defaultProps = {
	type: NewsCardTypes.simple,
	size: NewsCardSizes.medium,
	hideTag: false,
}
type NewsCardFeaturedProps = {
	id: string | number
	url: string
	title: string
	category: {
		href: string
		label: string
	}
	timestamp: string
	thumbnail: {
		src: string
		srcRetina: string
		srcPlaceholder: string
		alt: string
		loadEagerly?: boolean
	}
} & typeof defaultProps

const NewsCardFeaturedImage = styled(LazyImage)`
	${positionAbsoluteAndStretch}
`

const NewsCardFeaturedBody = styled.div`
	${positionAbsoluteAndStretch}
	pointer-events: none;
	transition: ${transition('background')};
`

const TagWrapper = styled.div``

const NewsCardFeaturedHeader = styled.div<{ isLarge: boolean; hideTag: boolean }>`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	display: flex;
	align-items: flex-start;
	justify-content: ${props => (props.hideTag ? 'flex-end' : 'space-between')};
	padding: ${props => (props.isLarge ? '12px 12px 12px 20px' : '8px 12px 8px 20px')};
	pointer-events: none;

	${mediaBreakpointUpSm} {
		padding: ${props => (props.isLarge ? '12px 16px 12px 20px' : '8px 12px 8px 20px')};
	}

	${TagLinkText} {
		pointer-events: all;
	}

	${TagWrapper} {
		padding-top: ${props => (props.isLarge ? '8px' : '12px')};
	}
`

const NewsCardFeaturedMain = styled.div<{ isLarge: boolean; isVideo: boolean }>`
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 100%;
	max-height: ${props => (props.isLarge ? '65%' : '90%')};
	padding: 18px 20px;
	${props => mainGradient(props.isLarge, props.isVideo ? props.theme.colors.black : props.theme.colors.nightBlue)}
`

const NewsCardFeaturedWrapper = styled.div<{ isVideo: boolean }>`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: calc(100% + 30px);
	margin-right: -15px;
	margin-left: -15px;
	pointer-events: all;
	${props => boxShadowRgbaWithHex('0 1px 2px 0', props.theme.colors.black, 0.3)}
	${mediaBreakpointUpSm} {
		width: auto;
		margin-right: 0;
		margin-left: 0;
	}

	${mediaBreakpointUpLg} {
		overflow: hidden;
		border-radius: 2px;
	}

	&:hover {
		${NewsCardFeaturedBody} {
			${props =>
				rgbaWithHex(props.isVideo ? props.theme.colors.black : props.theme.colors.nightBlue, 0.4, 'background')}
		}
	}
`

const NewsCardFeaturedLink = styled.a`
	pointer-events: all;

	&:hover {
		text-decoration: none;
	}
`

const NewsCardFeaturedTitle = styled(Title)<{ isLarge: boolean }>`
	padding-top: ${props => (props.isLarge ? '6px' : '2px')};
	margin: ${props => (props.isLarge ? '0 0 3px' : '0 0 5px')};
	font-size: ${props => (props.isLarge ? '28px' : '24px')};
	line-height: ${props => (props.isLarge ? '30px' : '28px')};

	${mediaBreakpointUpLg} {
		margin: 0 0 5px;
		font-size: ${props => (props.isLarge ? '34px' : '24px')};
		line-height: ${props => (props.isLarge ? '38px' : '28px')};
	}

	/**
	 * Since IE10 and above don't support 'line-clamp' and to not
	 * refactor the current implementation, update the 'font-size'
	 * to the limit needed to avoid the text overflow inside the box.
	 */
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (min-width: 992px) {
		font-size: ${props => (props.isLarge ? '27px' : '17px')};
		line-height: ${props => (props.isLarge ? '32px' : '24px')};
	}

	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (min-width: 1200px) {
		font-size: ${props => (props.isLarge ? '30px' : '20px')};
		line-height: ${props => (props.isLarge ? '40px' : '30px')};
	}
`

const NewsCardFeaturedDate = styled.div<{ isLarge: boolean }>`
	pointer-events: all;

	${Paragraph} {
		font-size: ${props => (props.isLarge ? '16px' : '14px')};
		font-weight: 500;
		line-height: ${props => (props.isLarge ? '19px' : '18px')};

		${mediaBreakpointUpLg} {
			font-size: ${props => (props.isLarge ? '18px' : '14px')};
			line-height: ${props => (props.isLarge ? '23px' : '18px')};
		}
	}
`

const NewsCardFeaturedCenteredIcon = styled.div`
	${positionAbsoluteAndStretch}
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;

	${NewsCardFeaturedLink} {
		margin-bottom: 27px;

		${mediaBreakpointUpLg} {
			margin-bottom: 0;
		}
	}
`

const NewsCardFeaturedComponent = ({
	id,
	type,
	url,
	thumbnail,
	title,
	category,
	timestamp,
	size,
	hideTag,
}: NewsCardFeaturedProps) => {
	const { isMobile } = useMediaQueryContext()
	const isPhotoGallery = type === NewsCardTypes.photoGallery
	const isVideo = type === NewsCardTypes.video
	const isLarge = !isMobile && size === NewsCardSizes.large
	const isMedium = size === NewsCardSizes.medium
	const aspectRatio = isLarge ? (isMobile ? '320/362' : '730/470') : '350/220'
	return (
		<NewsCardFeaturedWrapper isVideo={isVideo}>
			<NextLink href={url} passHref>
				<NewsCardFeaturedLink>
					<NewsCardFeaturedImage {...thumbnail} aspectRatio={aspectRatio} />
				</NewsCardFeaturedLink>
			</NextLink>
			<NewsCardFeaturedBody>
				<NewsCardFeaturedHeader isLarge={isLarge} hideTag={hideTag}>
					{!hideTag && (
						<ThemeProvider theme={lightTheme}>
							<TagWrapper>
								<Tag href={category.href}>{category.label}</Tag>
							</TagWrapper>
						</ThemeProvider>
					)}
					{isPhotoGallery && (
						<NextLink href={url} passHref>
							<NewsCardFeaturedLink>
								<SvgPhotoGallery suffix={`-${id}`} />
							</NewsCardFeaturedLink>
						</NextLink>
					)}
					{isVideo && !isLarge && (
						<NextLink href={url} passHref>
							<NewsCardFeaturedLink>
								{isMedium ? <SvgPlayMedium suffix={`-${id}`} /> : <SvgPlaySmall suffix={`-${id}`} />}
							</NewsCardFeaturedLink>
						</NextLink>
					)}
				</NewsCardFeaturedHeader>
				<NewsCardFeaturedMain isLarge={isLarge} isVideo={isVideo}>
					<ThemeProvider theme={darkTheme}>
						<NextLink href={url} passHref>
							<NewsCardFeaturedLink>
								<NewsCardFeaturedTitle size={3} maxLines={3} isLarge={isLarge}>
									{title}
								</NewsCardFeaturedTitle>
							</NewsCardFeaturedLink>
						</NextLink>
						<NewsCardFeaturedDate isLarge={isLarge}>
							<DateText timestamp={timestamp} />
						</NewsCardFeaturedDate>
					</ThemeProvider>
				</NewsCardFeaturedMain>
			</NewsCardFeaturedBody>
			{isVideo && isLarge && (
				<NewsCardFeaturedCenteredIcon>
					<NextLink href={url} passHref>
						<NewsCardFeaturedLink>
							{isMobile ? <SvgPlayLarge suffix={`-${id}`} /> : <SvgPlayExtraLarge suffix={`-${id}`} />}
						</NewsCardFeaturedLink>
					</NextLink>
				</NewsCardFeaturedCenteredIcon>
			)}
		</NewsCardFeaturedWrapper>
	)
}

NewsCardFeaturedComponent.defaultProps = defaultProps

export const NewsCardFeatured = React.memo(NewsCardFeaturedComponent)
