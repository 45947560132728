import React from 'react'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: 'Anexo',
	[Language.En]: 'Attachment',
}

export const SvgPaperclip: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-paperclip' + suffix

	return (
		<svg width={24} height={24} aria-labelledby={`${idPrefix}-title`} role="img" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language]}</title>
			<path
				fill="#0F2F7F"
				d="M12.422 4.229c1.839-1.839 4.83-1.839 6.668 0 1.838 1.838 1.838 4.828 0 6.666L9.6 20.493c-.634.635-1.48.981-2.384.981-.905 0-1.75-.346-2.385-.98-1.305-1.306-1.305-3.464 0-4.768l9.111-9.22c.768-.767 2.102-.767 2.87 0 .792.792.792 2.08 0 2.87l-8.73 8.84c-.27.268-.704.268-.972 0-.24-.24-.24-.732 0-.971l8.73-8.84c.256-.255.256-.671 0-.927-.245-.245-.68-.245-.926 0l-9.111 9.219c-.78.779-.78 2.046 0 2.825.753.753 2.072.753 2.825 0l9.491-9.598c1.302-1.302 1.302-3.421 0-4.724-1.26-1.26-3.464-1.26-4.724 0l-6.797 6.797c-.269.268-.703.268-.972 0-.268-.268-.268-.703 0-.971z"
			/>
		</svg>
	)
}
