import React from 'react'
import styled from 'styled-components'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: (inverted = false) => (inverted ? 'Página anterior' : 'Página seguinte'),
	[Language.En]: (inverted = false) => (inverted ? 'Previous page' : 'Next page'),
}

const SvgPaginationArrowRightPath = styled.path`
	fill: ${props => props.theme.color.primary};
`

export const SvgPaginationArrowRight: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, rotate = 0, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-pagination-arrow-right' + suffix

	return (
		<svg width={16} height={16} aria-labelledby={`${idPrefix}-title`} role="img" fill="none" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language](rotate === 180)}</title>
			<SvgPaginationArrowRightPath
				transform={`rotate(${rotate} 8 8)`}
				d="M10.5 12.603a.97.97 0 01-.72-.31.996.996 0 01.04-1.41L12 8.803H1.5c-.55 0-1-.45-1-1s.45-1 1-1H12l-2.19-2.08a.996.996 0 111.37-1.45l4 3.8c.21.19.32.46.32.73 0 .27-.11.54-.31.72l-4 3.8c-.19.19-.44.28-.69.28z"
			/>
		</svg>
	)
}
