import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactModal from 'react-modal'

interface ModalProps extends ReactModal.Props {
	className: string
	children: PropTypes.ReactNodeLike
}

// ! SSR issues 👀: https://github.com/reactjs/react-modal/issues/576
ReactModal.setAppElement('body')

const Modal = ({ className, children, ...props }: ModalProps) => {
	// ! It's required to define a `className` in order to
	// ! disable the default content styles: https://github.com/reactjs/react-modal/issues/603
	const contentClassName = `${className}__content`
	const overlayClassName = `${className}__overlay`

	return (
		<ReactModal portalClassName={className} className={contentClassName} overlayClassName={overlayClassName} {...props}>
			{children}
		</ReactModal>
	)
}

export const ModalAdapter = styled(Modal)`
	.ReactModal__Overlay {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 10;
		background-color: ${props => props.theme.colors.black};

		&--after-open {
			/* &--after-open */
		}

		&--before-close {
			/* &--before-open */
		}
	}

	.ReactModal__Content {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		overflow: auto;
		outline: none;

		/* TODO: Close modal when clicking outside of ReactModal__Content. We can't use pointer-events: none; because then we lose the scroll */

		&--after-open {
			/* &--after-open */
		}

		&--before-close {
			/* &--before-open */
		}
	}
`
