import React from 'react'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: 'Tempo',
	[Language.En]: 'Time',
}

export const SvgTime: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, stroke = '#0F2F7F', ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-time' + suffix

	return (
		<svg width={24} height={24} aria-labelledby={`${idPrefix}-title`} role="img" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language]}</title>
			<g fill="none" fillRule="evenodd" stroke={stroke} strokeWidth={1.5} transform="translate(4 4)">
				<circle cx={8} cy={8} r={7.25} />
				<path strokeLinecap="round" d="M8 4v4m0 0l2.828 2.828" />
			</g>
		</svg>
	)
}
