import React, { useState, useEffect, useCallback } from 'react'
import useSWR from 'swr'
import { Text, TextWeights } from 'components/text/text'
import { lightTheme } from 'theme/theme'
import styled from 'styled-components'
import { Language } from 'generated/sdk'
import { useTimeout } from 'react-use'
import { useLocationContext } from 'components/location-provider/location-provider'
import { PrimaryButton } from 'components/primary-button/primary-button'
import { SvgArrowDownCircle } from 'components/svgs/svg-arrow-down-circle'
import { LoadingCircle } from 'components/loading-circle/loading-circle'
import { dmsiLiveCondition } from 'utils/dmsi/urls'
import { mediaBreakpointUpXl } from 'theme/breakpoints'
import { boxShadowRgbaWithHex, transition } from 'theme/utils'
import { useInView } from 'react-intersection-observer'
import { notifyError } from 'services/sentry'

// match DMSI values
export enum TrafficPeriodStatus {
	ALL = 'ALL',
	TODAY = 'TODAY',
	TOMORROW = 'TOMORROW',
	NEXT7DAYS = 'NEXT7DAYS',
}

export enum TrafficConditionStatus {
	ALL = 'ALL',
	REABERTO = 'REABERTO',
	CONDICIONADO = 'CONDICIONADO',
	INTERMITENTE = 'INTERMITENTE',
	FECHADO = 'FECHADO',
}

export type TrafficDesktopCondition = {
	status: TrafficConditionStatus
	streets: string[]
	count: number
}

export type TrafficDesktopProps = {
	link: {
		href: string
		label: string
	}
}

export const trafficConditionStatusColors = {
	[TrafficConditionStatus.ALL]: lightTheme.colors.shamrockGreen,
	[TrafficConditionStatus.REABERTO]: lightTheme.colors.shamrockGreen,
	[TrafficConditionStatus.CONDICIONADO]: lightTheme.colors.marigold,
	[TrafficConditionStatus.INTERMITENTE]: lightTheme.colors.orange,
	[TrafficConditionStatus.FECHADO]: lightTheme.colors.lipstick,
}

const TRAFFIC_TEXT = {
	previous: {
		[Language.Pt]: 'Estado Anterior',
		[Language.En]: 'Previous Status',
	},
	next: {
		[Language.Pt]: 'Próximo Estado',
		[Language.En]: 'Next Status',
	},
}

const TrafficDesktopWrapper = styled.div`
	position: relative;
	display: flex;
	height: 107px;
	padding: 12px;
	background-color: ${props => props.theme.colors.paleGrey};
	border-radius: 2px;
	${props => boxShadowRgbaWithHex('0 2px 8px 0', props.theme.colors.black, 0.04)}
`

const TrafficDesktopLoading = styled(LoadingCircle)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`

const TrafficDesktopConditionStatusIconWrapper = styled.div`
	flex: 0 0 10px;
	padding-top: 4px;
`
const TrafficDesktopConditionStatusIcon = styled.span<{ status: TrafficConditionStatus }>`
	display: block;
	flex: 0 0 10px;
	width: 10px;
	height: 10px;
	background-color: ${props => trafficConditionStatusColors[props.status]};
	border-radius: 50%;
`

const TrafficDesktopConditionDetails = styled.div`
	/* 100% width - 10px status icon - 80px actions  */
	width: calc(100% - 90px);
	padding-left: 8px;
`

const TrafficDesktopConditionDetailsTitle = styled(Text)`
	padding-top: 2px;
	margin: 0 0 2px;
	font-size: 14px;
	line-height: 17px;

	${mediaBreakpointUpXl} {
		font-size: 16px;
		line-height: 19px;
		letter-spacing: -0.1px;
	}
`

const TrafficDesktopConditionDetailsStreet = styled(Text)`
	margin: 0;
	overflow: hidden;
	font-size: 14px;
	line-height: 18px;
	text-overflow: ellipsis;
	white-space: nowrap;

	&:not(:last-child) {
		margin-bottom: 4px;
	}
`

const TrafficDesktopConditionActions = styled.div`
	display: flex;
	flex: 0 0 80px;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
`

const TrafficDesktopConditionActionsIcons = styled.div`
	margin-top: -4px;
	margin-right: -2px;
`

const SvgArrowDownCircleButton = styled.button`
	padding: 2px;
	margin-left: 8px;
	background: transparent;
	border: none;

	.svg-arrow-down-circle {
		border-radius: 50%;
		${props => boxShadowRgbaWithHex('0 2px 10px 0', props.theme.colors.black, 0.04)}
	}

	.svg-arrow-down-circle__circle {
		transition: ${transition('fill')};
	}

	&:hover {
		.svg-arrow-down-circle__circle {
			fill: ${props => props.theme.colors.veryLightPink};
		}
	}

	&:focus {
		outline: none;

		.svg-arrow-down-circle__circle {
			fill: ${props => props.theme.colors.veryLightPinkTwo};
		}
	}
`

const TrafficDesktopComponent = ({ link }: TrafficDesktopProps) => {
	const { language } = useLocationContext()
	const [currentIndex, setCurrentIndex] = useState(0)
	const [trafficCurrentCondition, setTrafficCurrentCondition] = useState<TrafficDesktopCondition | null>(null)
	const [trafficConditionStreets, setTrafficConditionStreets] = useState<string[]>([])
	// @ts-ignore
	// eslint-disable-next-line
	const [isReady, cancel, reset] = useTimeout(6000)

	const { data, error } = useSWR('/api/dmsi-live-condition', dmsiLiveCondition)
	const { ref, inView } = useInView({ skip: !data || !!error })

	useEffect(() => {
		if (data) {
			setTrafficConditionStreets(data[currentIndex].streets.slice(0, 3))
			setTrafficCurrentCondition(data[currentIndex])
		}
	}, [currentIndex, data])

	useEffect(() => {
		if (!inView) {
			cancel()
		} else {
			reset()
		}
	}, [inView, cancel, reset])

	const updateCurrentIndex = useCallback(
		(count: number) => {
			const localData = data || []
			if (currentIndex + count < 0) {
				setCurrentIndex(localData.length - 1)
			} else if (currentIndex + count >= localData.length) {
				setCurrentIndex(0)
			} else {
				setCurrentIndex(currentIndex + count)
			}
			reset()
		},
		[reset, currentIndex, data, setCurrentIndex]
	)

	const readyState = isReady()

	useEffect(() => {
		if (readyState === true) {
			updateCurrentIndex(1)
			reset()
		}
	}, [readyState, updateCurrentIndex, reset])

	if (error) {
		notifyError(error)
		return null
	}

	if (!data) {
		return (
			<TrafficDesktopWrapper>
				<TrafficDesktopLoading diameter={28} strokeWidth={3} color="twilightBlue" />
			</TrafficDesktopWrapper>
		)
	}

	return (
		<TrafficDesktopWrapper ref={ref}>
			<TrafficDesktopConditionStatusIconWrapper>
				<TrafficDesktopConditionStatusIcon status={trafficCurrentCondition?.status || TrafficConditionStatus.ALL} />
			</TrafficDesktopConditionStatusIconWrapper>
			<TrafficDesktopConditionDetails>
				<TrafficDesktopConditionDetailsTitle weight={TextWeights.bold} maxLines={1}>
					Trânsito {trafficCurrentCondition && trafficCurrentCondition.status.toLowerCase()}
				</TrafficDesktopConditionDetailsTitle>
				{trafficConditionStreets.map((street, i) => (
					<TrafficDesktopConditionDetailsStreet title={street} key={i}>
						{street}
					</TrafficDesktopConditionDetailsStreet>
				))}
			</TrafficDesktopConditionDetails>
			<TrafficDesktopConditionActions>
				<TrafficDesktopConditionActionsIcons>
					<SvgArrowDownCircleButton onClick={() => updateCurrentIndex(-1)}>
						<SvgArrowDownCircle
							suffix="-traffic-desktop"
							rotate={180}
							customTitle={TRAFFIC_TEXT.previous[language]}
							focusable="false"
						/>
					</SvgArrowDownCircleButton>
					<SvgArrowDownCircleButton onClick={() => updateCurrentIndex(1)}>
						<SvgArrowDownCircle suffix="-traffic-desktop" customTitle={TRAFFIC_TEXT.next[language]} focusable="false" />
					</SvgArrowDownCircleButton>
				</TrafficDesktopConditionActionsIcons>
				<PrimaryButton suffix="view-traffic" href={link.href} showArrow={false} small>
					{link.label}
				</PrimaryButton>
			</TrafficDesktopConditionActions>
		</TrafficDesktopWrapper>
	)
}

export const TrafficDesktop = React.memo(TrafficDesktopComponent)
