import React from 'react'
import styled from 'styled-components'

const SvgTwitterMediumPath = styled.path`
	fill: ${props => props.theme.color.primary};
`

export const SvgTwitterMedium: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string }
> = ({ suffix, ...props }) => {
	const idPrefix = 'svg-twitter-medium' + suffix
	return (
		<svg width={16} height={16} aria-labelledby={`${idPrefix}-title`} role="img" fill="none" {...props}>
			<title id={`${idPrefix}-title`}>Twitter</title>
			<SvgTwitterMediumPath d="M15.84 3.485a6.91 6.91 0 01-1.871.5 3.162 3.162 0 001.428-1.75 6.595 6.595 0 01-2.059.766A3.295 3.295 0 0010.966 2C9.168 2 7.721 3.422 7.721 5.165c0 .25.022.492.075.721a9.274 9.274 0 01-6.693-3.31 3.123 3.123 0 00-.445 1.6 3.15 3.15 0 001.443 2.63 3.272 3.272 0 01-1.467-.39v.035c0 1.537 1.125 2.814 2.601 3.109-.264.07-.552.104-.851.104-.208 0-.418-.012-.615-.054a3.27 3.27 0 003.034 2.204 6.635 6.635 0 01-4.026 1.35c-.26.002-.52-.013-.777-.044a9.318 9.318 0 004.982 1.42c5.975 0 9.242-4.823 9.242-9.004 0-.14-.005-.275-.012-.409a6.39 6.39 0 001.628-1.642z" />
		</svg>
	)
}
