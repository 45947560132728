import React from 'react'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: 'Fotogaleria',
	[Language.En]: 'Photo gallery',
}

export const SvgPhotoGallerySmall: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-photo-gallery-small' + suffix

	return (
		<svg width={16} height={16} fill="none" aria-labelledby={`${idPrefix}-title`} role="img" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language]}</title>
			<g filter={`url(#${idPrefix}-filter0_d)`}>
				<path
					d="M12.49 10.286h2.023c.19 0 .344-.162.344-.36v-7.28a.353.353 0 00-.344-.36H4.915a.353.353 0 00-.344.36v2.116h7.146c.426 0 .773.363.773.811v4.713z"
					fill="#fff"
				/>
			</g>
			<g filter={`url(#${idPrefix}-filter1_d)`}>
				<path
					d="M1.143 5.946v7.305c0 .217.176.392.392.392h9.662a.392.392 0 00.392-.392V5.946a.392.392 0 00-.392-.393H1.535a.392.392 0 00-.392.393z"
					fill="#fff"
				/>
			</g>
			<defs>
				<filter
					id={`${idPrefix}-filter0_d`}
					x={3.571}
					y={2.286}
					width={12.286}
					height={10}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB">
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy={1} />
					<feGaussianBlur stdDeviation={0.5} />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
				</filter>
				<filter
					id={`${idPrefix}-filter1_d`}
					x={0.143}
					y={5.553}
					width={12.446}
					height={10.09}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB">
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy={1} />
					<feGaussianBlur stdDeviation={0.5} />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
				</filter>
			</defs>
		</svg>
	)
}
