import React from 'react'
import styled from 'styled-components'

const SvgTwitterSmallPath = styled.path`
	fill: ${props => props.theme.color.primary};
`

export const SvgTwitterSmall: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string }
> = ({ suffix, ...props }) => {
	const idPrefix = 'svg-twitter-small' + suffix
	return (
		<svg width={16} height={16} aria-labelledby={`${idPrefix}-title`} role="img" fill="none" {...props}>
			<title id={`${idPrefix}-title`}>Twitter</title>
			<SvgTwitterSmallPath d="M15.4 3.35a6.282 6.282 0 01-1.701.454 2.875 2.875 0 001.299-1.59c-.57.331-1.2.566-1.872.696A2.995 2.995 0 0010.969 2C9.335 2 8.02 3.293 8.02 4.877c0 .228.02.447.069.656a8.43 8.43 0 01-6.086-3.009 2.84 2.84 0 00-.404 1.454c0 .997.526 1.88 1.31 2.39a2.974 2.974 0 01-1.333-.353v.031c0 1.398 1.023 2.559 2.365 2.826a3.02 3.02 0 01-.774.095c-.189 0-.38-.01-.559-.049a2.97 2.97 0 002.759 2.004 6.031 6.031 0 01-3.66 1.227c-.243 0-.475-.01-.707-.04A8.47 8.47 0 005.529 13.4c5.432 0 8.402-4.385 8.402-8.185 0-.127-.004-.25-.01-.372A5.83 5.83 0 0015.4 3.35z" />
		</svg>
	)
}
