import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { parseISO, formatISO, isSameDay, isSameHour, getHours, getMinutes } from 'date-fns'
import { NextLink } from 'components/next-link/next-link'
import { Title } from 'components/title/title'
import { Text } from 'components/text/text'
import { SvgTime } from 'components/svgs/svg-time'
import { SvgLocationPin } from 'components/svgs/svg-location-pin'
import { mediaBreakpointUpLg } from 'theme/breakpoints'
import { formatDateLocale, formatDateTime } from 'utils/dates/index'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'
import { transition } from 'theme/utils'

export type EventDate = {
	start: string
	end: string
	repeating: EventDayOfWeek[]
}

export type EventDayOfWeek = {
	label: string
}

export type EventLocation = {
	locality: string
	address?: string | null
	latitude: number
	longitude: number
}

type EventInformationProps = {
	dates: EventDate[]
	locations: {
		location: EventLocation
	}[]
}

export const EVENT_TEXT = {
	date: {
		[Language.Pt]: 'Data',
		[Language.En]: 'Date',
	},
	location: {
		[Language.Pt]: 'Localização',
		[Language.En]: 'Location',
	},
	allDay: {
		[Language.Pt]: 'O dia todo',
		[Language.En]: 'All day',
	},
}

const EventInformationWrapper = styled.div`
	padding: 12px 15px 15px 15px;
	overflow: hidden;
	background-color: ${props => props.theme.background};
	border: solid 1px ${props => props.theme.colors.veryLightPinkTwo};
	border-radius: 2px;
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.04);

	${mediaBreakpointUpLg} {
		padding: 13px 15px 19px 15px;
	}
`

const EventInformationSeparator = styled.hr`
	margin-top: 10px;
	margin-bottom: 13px;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
`

const EventInformationTitleWrapper = styled.div`
	display: flex;
	align-items: center;
	margin: 0 0 7px -4px;

	${mediaBreakpointUpLg} {
		margin: 0 0 6px -4px;
	}
`

const EventInformationList = styled.ul`
	padding: 0;
	margin: 0;
	list-style: none;
`

const EventInformationItem = styled.li`
	padding: 0;
	margin: 0 0 5px;

	&:last-child {
		margin: 0;
	}
`

const EventInformationTitle = styled(Title)`
	position: relative;
	top: 2px;
	margin: 0;
	font-size: 16px;
	font-weight: 700;
	line-height: 20px;
	text-transform: uppercase;
`

const EventInformationSubTitle = styled(Title)`
	margin: 0;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
`

const EventInformationText = styled(Text)`
	margin: 5px 0 0;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
`

const EventInformationDaysOfWeek = styled(Text)`
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
`

const EventInformationLink = styled.a`
	color: ${props => props.theme.color.primary};
	text-decoration: underline;
	transition: ${transition('color')};

	&:hover,
	&:focus,
	&:active {
		color: ${props => props.theme.color.secondary};
	}
`

export const EventInformation = ({ dates, locations }: EventInformationProps) => {
	const themeContext = useContext(ThemeContext)
	const { language } = useLocationContext()

	return (
		<EventInformationWrapper>
			<div>
				<EventInformationTitleWrapper>
					<SvgTime
						suffix="-event-information"
						stroke={themeContext.color.primary}
						customTitle={EVENT_TEXT.date[language]}
					/>
					<EventInformationTitle size={2}>{EVENT_TEXT.date[language]}</EventInformationTitle>
				</EventInformationTitleWrapper>
				<EventInformationList>
					{dates.map((date, index) => {
						const startDate = parseISO(date.start)
						const endDate = parseISO(date.end)
						const startDateTimestamp = formatISO(startDate)
						const endDateTimestamp = formatISO(endDate)
						const isEventSameDay = isSameDay(startDate, endDate)
						const isEventSameHour = isSameHour(startDate, endDate)
						const startsBeginningOfDay = getHours(startDate) === 0 && getMinutes(startDate) === 0
						const endsEndOfDay = getHours(endDate) === 23 && getMinutes(endDate) === 59
						const isEventAllDay = isEventSameHour || (startsBeginningOfDay && endsEndOfDay)
						const daysOfWeek = date.repeating.map(item => item.label)

						return (
							<EventInformationItem key={index}>
								<EventInformationSubTitle size={3}>
									{date.start && !isEventSameDay && `${formatDateLocale(startDateTimestamp, language, true)} - `}
									{formatDateLocale(endDateTimestamp, language, true)}
								</EventInformationSubTitle>
								<EventInformationText>
									{isEventAllDay
										? EVENT_TEXT.allDay[language]
										: `${formatDateTime(startDateTimestamp, language)} - ${formatDateTime(endDateTimestamp, language)}`}
								</EventInformationText>
								{daysOfWeek.length > 0 && (
									<EventInformationDaysOfWeek>{daysOfWeek.join(', ')}</EventInformationDaysOfWeek>
								)}
							</EventInformationItem>
						)
					})}
				</EventInformationList>
			</div>
			<EventInformationSeparator />
			<div>
				<EventInformationTitleWrapper>
					<SvgLocationPin suffix="-event-information" stroke={themeContext.color.primary} />
					<EventInformationTitle size={2}>{EVENT_TEXT.location[language]}</EventInformationTitle>
				</EventInformationTitleWrapper>
				<EventInformationList>
					{locations.map(({ location }, index) => {
						return (
							<EventInformationItem key={index}>
								<EventInformationSubTitle size={3}>
									<NextLink
										href={`https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`}
										passHref>
										<EventInformationLink>
											{location.address && <>{location.address} - </>}
											{location.locality}
										</EventInformationLink>
									</NextLink>
								</EventInformationSubTitle>
							</EventInformationItem>
						)
					})}
				</EventInformationList>
			</div>
		</EventInformationWrapper>
	)
}
